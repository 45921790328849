import { PickingModeName } from 'api-schema/lib/model/autostore-port';
import { ReactNode } from 'react';
import { ReactComponent as ArrowsSyncIcon } from '../../../assets/img/icons/arrows-sync.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/img/icons/calendar.svg';
import { ReactComponent as ImportantIcon } from '../../../assets/img/icons/important-diamond.svg';
import { ReactComponent as PackageIcon } from '../../../assets/img/icons/package.svg';
import { Chip, ChipVariant } from '../Chip/Chip';

export type PortPickingModeChipVariant = PickingModeName | 'TRANSITIONING';

export type PortPickingModeChipProps = {
  children: ReactNode;
  variant?: PortPickingModeChipVariant;
};

const getVariantIcon = (variant: PortPickingModeChipVariant): ReactNode => {
  switch (variant) {
    case 'SAMEDAY':
      return <CalendarIcon />;
    case 'TRANSITIONING':
      return <ArrowsSyncIcon />;
    case 'PRIORITY':
      return <ImportantIcon />;
    case 'B2B':
      return <PackageIcon />;
    case 'STANDARD':
    case 'MANUAL_PACK':
    case 'STOCK_REMOVAL':
    default:
      return null;
  }
};

const portPickingModeChipVariantMap: Record<
  PortPickingModeChipVariant,
  ChipVariant
> = {
  SAMEDAY: 'primary',
  TRANSITIONING: 'info',
  STANDARD: 'secondary',
  B2B: 'warning',
  PRIORITY: 'warning',
  MANUAL_PACK: 'warning',
  STOCK_REMOVAL: 'warning',
};

export function PortPickingModeChip({
  children,
  variant = 'STANDARD',
}: PortPickingModeChipProps) {
  const icon = getVariantIcon(variant);
  const chipVariant = portPickingModeChipVariantMap[variant];
  return (
    <Chip variant={chipVariant} icon={icon}>
      {children}
    </Chip>
  );
}
