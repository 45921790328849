import { PutawayMode, Transfer } from 'api-schema/lib/model';

import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { FeatureFlags } from 'api-schema/lib/model/featureFlags';
import { PortPutawayModeChip } from '../../../../../components/admin/PortPutawayModeChip';
import { AdminThemeInterface } from '../../../../../themes/AdminTheme';

export type PutawayPanelProps = {
  currentTransfer?: Transfer;
  portId: number;
  putawayMode?: PutawayMode;
  featureFlags?: FeatureFlags;
};

export function PutawayPanel({
  currentTransfer,
  portId,
  putawayMode,
}: PutawayPanelProps) {
  const { spacing } = useTheme<AdminThemeInterface>();
  return (
    <Box display="flex" justifyContent="space-between">
      <Box flex="1 0">
        <Typography variant="h2" gutterBottom>
          PORT: {portId}
        </Typography>
      </Box>

      {currentTransfer && (
        <Box display="flex">
          <Box
            flex="1 0"
            textAlign="right"
            display="inline-flex"
            gridGap={spacing(1)}
          >
            {putawayMode && (
              <Typography>
                <PortPutawayModeChip variant={putawayMode} />
              </Typography>
            )}
            <Typography variant="h2" gutterBottom>
              Transfer: {currentTransfer.id}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
