"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryEvent = exports.inventoryItemAllocatedResetSchema = exports.inventoryAllocatedResetSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.inventoryAllocatedResetSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INVENTORY_ALLOCATED_RESET'),
    payload: zod_1.z.object({}),
});
exports.inventoryItemAllocatedResetSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('INVENTORY_ITEM_ALLOCATED_RESET'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string(),
        barcode: zod_1.z.string(),
    }),
});
exports.InventoryEvent = zod_1.z.union([
    exports.inventoryAllocatedResetSchema,
    exports.inventoryItemAllocatedResetSchema,
]);
