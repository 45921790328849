"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackAndDispatchCommandsOutcome = exports.bailOutPackingStationOutcomeSchema = exports.updateBlindCountQuantityOutcomeSchema = exports.cancelPackingOrderOutcomeSchema = exports.cancelOrderPackingFailedOutcomeSchema = exports.skipInsertOutcomeSchema = exports.addInsertOutcomeSchema = exports.InsertNotRequiredForMerchant = exports.RevertToTroublshootLabelOutcome = exports.TransferParcelToTroubleshootOutcome = exports.CompleteLabelPrintingOutcome = exports.GenerateTroubleshootLabelOutcome = exports.CreateLabelOutcome = exports.CancelParcelSortingOutcome = exports.SortParcelOutcome = exports.ChangeParcelSizeOutcome = exports.UnableToPackOrderOutcome = exports.PrintTroubleshootLabelOutcome = exports.StartLabelPrintingOutcome = exports.SelectParcelOutcome = exports.CheckPackingToteOutcome = exports.ScanBarcodeAtPackingStationOutcome = exports.ClosePackStationOutcome = exports.OpenPackStationOutcome = void 0;
const z = __importStar(require("zod"));
const fulfilmentOrder_1 = require("./fulfilmentOrder");
const generic_1 = require("./generic");
const labelTroubleshoot_1 = require("./labelTroubleshoot");
const packing_1 = require("./packing");
const printing_1 = require("./printing");
const warehouse_1 = require("./warehouse");
exports.OpenPackStationOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.ClosePackStationOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.ScanBarcodeAtPackingStationOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
    packing_1.NoOrderId,
    packing_1.UnknownToteId,
    packing_1.NoPicklistAssigned,
    packing_1.NoFulfilmentOrder,
    packing_1.DuplicatedCompletedPicklist,
    packing_1.PicklistNotFound,
    packing_1.invalidPicklistStatusSchema,
    packing_1.InvalidTote,
    packing_1.ToteContainsResolutionPicklist,
    packing_1.ToteAlreadyScanned,
    packing_1.PackingStationNotFound,
    packing_1.unknownBarcodeSchema,
    packing_1.invalidFulfilmentOrderStatusSchema,
]);
exports.CheckPackingToteOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.SelectParcelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.StartLabelPrintingOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.PrintTroubleshootLabelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.UnableToPackOrderOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.ChangeParcelSizeOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.SortParcelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
    packing_1.PicklistNotFound,
    packing_1.UnknownToteId,
    packing_1.OrderPackingFailed,
    packing_1.PackingStationNotFound,
    packing_1.NoFulfilmentOrder,
]);
exports.CancelParcelSortingOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.CreateLabelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
    packing_1.RequestLabelFailed,
]);
exports.GenerateTroubleshootLabelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    packing_1.TroubleshootLabelGenerationFailed,
    generic_1.GenericError,
]);
exports.CompleteLabelPrintingOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.TransferParcelToTroubleshootOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
    packing_1.PicklistNotFound,
]);
exports.RevertToTroublshootLabelOutcome = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    generic_1.GenericError,
]);
exports.InsertNotRequiredForMerchant = z.object({
    outcome: z.literal('INSERT_NOT_REQUIRED_FOR_MERCHANT'),
});
exports.addInsertOutcomeSchema = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    packing_1.NoFulfilmentOrder,
    packing_1.PackingStationNotFound,
    labelTroubleshoot_1.InvalidOrderStatus,
    exports.InsertNotRequiredForMerchant,
]);
exports.skipInsertOutcomeSchema = z.union([
    generic_1.Success,
    packing_1.InvalidStationStatus,
    packing_1.NoFulfilmentOrder,
    packing_1.PackingStationNotFound,
    labelTroubleshoot_1.InvalidOrderStatus,
    exports.InsertNotRequiredForMerchant,
]);
exports.cancelOrderPackingFailedOutcomeSchema = z.enum([
    'FULFILMENT_ORDER_NOT_FOUND',
    'INVALID_FULFILMENT_ORDER_STATUS',
    'PACKING_STATION_NOT_FOUND',
    'INVALID_PACKING_STATION_STATUS',
    'ORDER_PACKING_CANCELLATION_REASONS_NOT_PROVIDED',
    'TOTE_ID_NOT_FOUND',
    'LABEL_GENERATION_FAILED',
    'SAVE_LABEL_FAILED',
    'WAREHOUSE_NOT_FOUND',
    'PRINTER_NOT_FOUND',
    'PICKLIST_ID_NOT_FOUND',
]);
exports.cancelPackingOrderOutcomeSchema = z.union([
    generic_1.Success,
    z.object({ outcome: exports.cancelOrderPackingFailedOutcomeSchema }),
]);
exports.updateBlindCountQuantityOutcomeSchema = z.union([
    generic_1.Success,
    packing_1.PackingStationNotFound,
    packing_1.toteNotFoundOutcomeSchema,
    warehouse_1.warehouseNotFoundOutcomeSchema,
    fulfilmentOrder_1.fulfilmentOrderNotFoundOutcomeSchema,
    packing_1.PicklistNotFound,
    packing_1.blindCountTotalItemQuantityInvalidOutcomeSchema,
]);
exports.bailOutPackingStationOutcomeSchema = z.discriminatedUnion('outcome', [
    generic_1.Success.extend({
        picklistId: z.string().min(1),
        fulfilmentOrderId: z.string(),
        toteId: z.string(),
        actions: z.object({
            transferFulfilmentOrderTo: z
                .enum(['TROUBLESHOOT', 'PACKING_QUEUE'])
                .optional(),
            shouldResetPackingStation: z.boolean(),
        }),
    }),
    packing_1.PackingStationNotFound,
    fulfilmentOrder_1.fulfilmentOrderNotFoundOutcomeSchema,
    packing_1.PicklistNotFound.pick({ outcome: true }),
    packing_1.toteNotFoundOutcomeSchema,
]);
exports.PackAndDispatchCommandsOutcome = z.union([
    exports.OpenPackStationOutcome,
    exports.ClosePackStationOutcome,
    exports.ScanBarcodeAtPackingStationOutcome,
    exports.CheckPackingToteOutcome,
    exports.SelectParcelOutcome,
    exports.StartLabelPrintingOutcome,
    exports.SortParcelOutcome,
    exports.CreateLabelOutcome,
    exports.CancelParcelSortingOutcome,
    exports.GenerateTroubleshootLabelOutcome,
    exports.PrintTroubleshootLabelOutcome,
    exports.CompleteLabelPrintingOutcome,
    exports.TransferParcelToTroubleshootOutcome,
    exports.RevertToTroublshootLabelOutcome,
    printing_1.PrintFulfilmentOrderOutcomes,
    printing_1.PrintTroubleshootLabelOutcomes,
    exports.addInsertOutcomeSchema,
    exports.skipInsertOutcomeSchema,
    exports.cancelPackingOrderOutcomeSchema,
    exports.updateBlindCountQuantityOutcomeSchema,
]);
