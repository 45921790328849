"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickingModeEventSchema = exports.pickingModeNameEventSchema = exports.portEventBaseSchema = exports.putawayModeSchema = exports.affectedPartition = exports.LabelPaths = exports.autostoreTaskIdSchema = exports.autostoreBinIdSchema = exports.portIdSchema = exports.StringId = exports.eventBaseSchema = void 0;
const zod_1 = require("zod");
const date_1 = require("./date");
exports.eventBaseSchema = zod_1.z.object({
    id: zod_1.z.number().int(),
    type: zod_1.z.string(),
    payload: zod_1.z.unknown().optional(),
    version: zod_1.z.number().int().positive().default(1),
    created_at: date_1.dateSchema,
    warehouseId: zod_1.z.string(),
    portId: zod_1.z.number().int().positive().optional(),
    organisationId: zod_1.z.string().min(1).optional(),
    fulfilmentOrderIds: zod_1.z.array(zod_1.z.string()).optional(),
    transferId: zod_1.z.string().min(1).optional(),
    isSkippable: zod_1.z.boolean(),
});
exports.StringId = zod_1.z.string().min(1);
exports.portIdSchema = zod_1.z.number().int().positive();
exports.autostoreBinIdSchema = zod_1.z.number().int().positive().gte(10000);
exports.autostoreTaskIdSchema = zod_1.z.number().int().positive().gte(10000);
exports.LabelPaths = zod_1.z.object({
    pdf: zod_1.z.string(),
    zpl: zod_1.z.string().optional(),
});
exports.affectedPartition = zod_1.z.object({
    binId: zod_1.z.string().min(1),
    partitionNumber: zod_1.z.number(),
    autostoreBinId: zod_1.z.number(),
    quantityAllocated: zod_1.z.number(),
});
exports.putawayModeSchema = zod_1.z.enum(['NUMBER_INPUT', 'SCAN']);
exports.portEventBaseSchema = exports.eventBaseSchema.extend({
    portId: zod_1.z.number().int().positive(),
});
// In future these categories need to removed from our event schema.
// Events should not store the autostoreCategory as this is stateful information
// which can be derived at run time. Once removed we will be able to change the mapping
// of a pickingMode to a autostoreCategory in state without worrying about migrating
// historical events
const pickingModeAutoStoreEventCategories = {
    STANDARD: zod_1.z.literal(1),
    SAMEDAY: zod_1.z.literal(2),
    PRIORITY: zod_1.z.literal(3),
    B2B: zod_1.z.literal(4),
    MANUAL_PACK: zod_1.z.literal(5),
    STOCK_REMOVAL: zod_1.z.literal(6),
};
const standardPickingModeNameSchema = zod_1.z.literal('STANDARD');
const samedayPickingModeNameSchema = zod_1.z.literal('SAMEDAY');
const priorityPickingModeNameSchema = zod_1.z.literal('PRIORITY');
const b2bPickingModeNameSchema = zod_1.z.literal('B2B');
const manualPackPickingModeNameSchema = zod_1.z.literal('MANUAL_PACK');
const stockRemovalPickingModeNameSchema = zod_1.z.literal('STOCK_REMOVAL');
exports.pickingModeNameEventSchema = zod_1.z.union([
    standardPickingModeNameSchema,
    samedayPickingModeNameSchema,
    priorityPickingModeNameSchema,
    b2bPickingModeNameSchema,
    manualPackPickingModeNameSchema,
    stockRemovalPickingModeNameSchema,
]);
exports.pickingModeEventSchema = zod_1.z.union([
    zod_1.z.object({
        name: standardPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.STANDARD,
    }),
    zod_1.z.object({
        name: samedayPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.SAMEDAY,
    }),
    zod_1.z.object({
        name: priorityPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.PRIORITY,
    }),
    zod_1.z.object({
        name: b2bPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.B2B,
    }),
    zod_1.z.object({
        name: manualPackPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.MANUAL_PACK,
    }),
    zod_1.z.object({
        name: stockRemovalPickingModeNameSchema,
        autostoreCategory: pickingModeAutoStoreEventCategories.STOCK_REMOVAL,
    }),
]);
