import {
  IntermediaryStationCommandResultMessage,
  InvalidSequencedResultMessageType,
  LabelTroubleshootCommandResultMessageType,
  PackAndDispatchCommandResultMessageType,
  PickCommandResultMessageType,
  PutawayCommandResultMessageType,
  ShortPickTroubleshootCommandResultMessageType,
} from 'api-schema/lib/websocket';
import { isNever } from '../isNever';

type OmitByDiscriminator<T, N> = T extends { outcome: N } ? never : T;

export const mapCommandErrorToUserMessage = (
  commandResult:
    | OmitByDiscriminator<
        | PutawayCommandResultMessageType['result']
        | PickCommandResultMessageType['result']
        | PackAndDispatchCommandResultMessageType['result']
        | InvalidSequencedResultMessageType['result']
        | ShortPickTroubleshootCommandResultMessageType['result']
        | LabelTroubleshootCommandResultMessageType['result']
        | IntermediaryStationCommandResultMessage['result'],
        'SUCCESS'
      >
    | { outcome: 'UNEXPECTED_ERROR' }
): string => {
  switch (commandResult.outcome) {
    case 'INVALID_PORT_STATUS': {
      const { expectedPortStatus, currentPortStatus } = commandResult;
      return `Invalid Port Status: Expected port status to be [${expectedPortStatus}] but port state was [${currentPortStatus}]. Contact technical support.`;
    }
    case 'NO_AUTOSTORE': {
      return `No Autostore is configured for this warehouse or fulfilment centre. Contact technical support.`;
    }
    case 'NO_MATCHING_BINS_READY': {
      return `There are currently no empty bins ready. Please try requesting a bin again in a minute.`;
    }
    case 'NO_MATCHING_BINS_EXIST': {
      return `There are no empty bins currently available. Please try again in a few minutes or contact operations supervisor.`;
    }
    case 'ERROR': {
      return `An unexpected error has occurred. Please try again soon. If the problem persists, please escalate.`;
    }
    case 'NO_CURRENT_BIN': {
      return 'Cannot add item before a bin is ready in port.';
    }
    case 'COULD_NOT_GET_AUTOSTORE_PORT_STATUS': {
      return 'Autostore failed to get port status.';
    }
    case 'AUTOSTORE_BIN_AT_PORT_MISMATCH': {
      return 'Autostore bin at port mismatch bin in port state.';
    }
    case 'AUTOSTORE_PORT_TASK_ID_MISMATCH': {
      return 'Task at autostore port status mismatch task in port state';
    }
    case 'AUTOSTORE_BIN_AT_PORT_NOT_READY': {
      return 'Autostore bin at port is not ready/arrived.';
    }
    // TODO(WMS-735): address orphaned todo
    // eslint-disable-next-line todo-plz/ticket-ref
    // TODO: add more props to this event or change to use BARCODE_DOES_NOT_MATCH_ANY_RETAIL_UNIT
    case 'NO_BARCODE_RETAIL_UNIT_MATCH': {
      return `Scanned barcode does not match any item in the current transfer.`;
    }
    case 'INVALID_TARGET_PARTITION': {
      return `That partition currently contains another type of item and cannot be co-mingled.`;
    }
    case 'NO_CURRENT_TRANSFER': {
      return 'Cannot add item without a current transfer.';
    }
    case 'BIN_NOT_EMPTY': {
      return 'The bin currently contains items.';
    }
    case 'TARGET_PARTITION_EMPTY': {
      return 'Cannot remove items from an empty partition';
    }
    case 'INVALID_PORT_TYPE': {
      return 'This type of port cannot do that.';
    }
    case 'INVALID_LOCATION_ID': {
      return 'Unexpected or invalid location was scanned.';
    }
    case 'INVALID_TOTE': {
      return 'Unexpected or invalid tote was scanned.';
    }
    case 'INVALID_STATE': {
      return 'Unexpected or invalid action was attempted.';
    }
    case 'MISSING_TASK_ID': {
      return 'Autostore returned an unexpected response.';
    }
    case 'NO_TASK_GROUP_AVAILABLE': {
      return 'No task group is currently available.';
    }
    case 'NO_PICK_ASSIGNED': {
      return 'No pick is currently assigned to this port.';
    }
    case 'NO_TASK_ASSIGNED': {
      return 'No task is currently assigned to this port.';
    }
    case 'NO_PICKING_MODES_FOUND': {
      return 'No categories found for this port.';
    }
    case 'NO_TASK_GROUP_ASSIGNED': {
      return 'No task group is currently assigned to this port.';
    }
    case 'NO_PREVIOUS_PICK': {
      return 'There is no previous pick to return to.';
    }
    case 'NO_PICK_TOTES_PUSHED': {
      return 'There are no pick totes pushed.';
    }
    case 'NO_ORDER_ID': {
      const { toteId } = commandResult;
      return `Tote #${toteId} has no order assigned to it. Please alert your Manager.`;
    }
    case 'UNKNOWN_TOTE_ID': {
      return 'Tote ID not recognised. Please try again. If the problem persists, please alert your Manager.';
    }
    case 'UNKNOWN_BARCODE': {
      return 'Barcode not recognised. Please try again. If the problem persists, please alert your Manager.';
    }
    case 'INVALID_STATION_STATUS': {
      const { currentStatus } = commandResult;
      return `Cannot perform the action under current station status: ${currentStatus}`;
    }
    case 'NO_PICKLIST_ASSIGNED': {
      if ('toteId' in commandResult) {
        return `Tote #${commandResult.toteId} has no order assigned to it. Please alert your manager.`;
      }
      if ('toteLocationsWithoutPicklist' in commandResult) {
        const toteIds = commandResult.toteLocationsWithoutPicklist.map(
          (location) => location.toteId
        );
        return `Totes ${toteIds} have no order assigned to it. Please alert your manager.`;
      }
      return 'No picklist assigned to this tote.';
    }
    case 'NO_TOTE_ASSIGNED_TO_TOTE_LOCATION': {
      if ('toteLocationsWithoutToteId' in commandResult) {
        return `No tote assigned to tote locations ${commandResult.toteLocationsWithoutToteId}.`;
      }
      return 'No tote assigned to this tote location.';
    }
    case 'INVALID_PICKLIST_STATUS': {
      const { toteId } = commandResult;
      return `Tote #${toteId} has invalid status.`;
    }
    case 'NO_FULFILMENT_ORDER': {
      return 'No fulfilment order present in this tote.';
    }
    case 'PICKLIST_NOT_FOUND': {
      const { picklistId } = commandResult;
      return `Picklist ${picklistId} can not found`;
    }
    case 'REQUEST_LABEL_FAILED': {
      return 'Some error happened when creating label';
    }
    case 'ORDER_PACKING_FAILED': {
      const { orderId } = commandResult;
      return `Order packing failed - #${orderId}`;
    }
    case 'TROUBLESHOOT_LABEL_GENERATION_FAILED': {
      return 'Troubleshoot label generation failed, please notify your manager.';
    }
    case 'NO_ZPL_LABEL_AVAILABLE': {
      return 'Unable to print to Zebra printer due to missing ZPL label file.';
    }
    case 'UNKNOWN_ORDER': {
      return 'Cannot print, order not found';
    }
    case 'INVALID_ORDER_STATUS': {
      const { expectedStatus, actualStatus } = commandResult;
      return `Expected order status to be one of ${expectedStatus.join(
        ', '
      )} but it's current status is ${actualStatus}`;
    }
    case 'UNKNOWN_PRINTER': {
      const { printerName } = commandResult;
      return `Cannot find printer ${printerName}`;
    }
    case 'INVALID_QUANTITY': {
      return 'Invalid quantity.';
    }
    case 'INVALID_SEQUENCE_NUMBER': {
      return 'Invalid sequence number.';
    }
    case 'UNKNOWN_WAREHOUSE': {
      return 'Warehouse could not be found.';
    }
    case 'MISSING_WAREHOUSE_ID': {
      return 'Warehouse does not have an id';
    }
    case 'UNKNOWN_STATION': {
      return 'Station could not be found.';
    }
    case 'STATION_NOT_FOUND': {
      const { stationId } = commandResult;
      return `Cannot find the station with id: ${stationId}`;
    }
    case 'UNKNOWN_TOTE_ID_SCANNED': {
      return 'Tote ID not recognised. Please try again. If the problem persists, please alert your Manager.';
    }
    case 'RESOLUTION_TOTE_ID_SCANNED': {
      const { toteId, resolutionToteId } = commandResult;
      return `Resolution tote #${resolutionToteId} contains items for order tote #${toteId}.`;
    }
    case 'NO_ORDER_ASSIGNED': {
      const { toteId } = commandResult;
      return `Tote #${toteId} has no order assigned to it. Please alert your Manager.`;
    }
    case 'INVALID_STORAGE_TYPE': {
      return 'Invalid tote id entered.';
    }
    case 'TROUBLESHOOTING_NOT_REQUIRED': {
      const { toteId } = commandResult;
      return `Tote #${toteId} does not require troubleshooting. Please transfer the tote to Packing.`;
    }
    case 'ORIGINAL_PICKLIST_NOT_FOUND': {
      return 'Original picklist not found';
    }
    case 'RESOLUTION_TOTE_NOT_REQUIRED': {
      const { toteId } = commandResult;
      return `Resolution tote #${toteId} contains items that are no longer required. Please alert your manager.`;
    }
    case 'STATE_MACHINE_INVALID_EVENT': {
      return `State machine failed to handle the event`;
    }
    case 'ORDER_NOT_PICKED_YET': {
      return `Tote #${commandResult.toteId} has not finished picking yet. Please alert your manager.`;
    }
    case 'RESOLUTION_PICKLIST_NOT_PICKED': {
      return 'The resolution picklist for this order has not been picked yet.';
    }
    case 'MISSING_PICKLIST_OR_TOTE_ID': {
      return 'The station is missing the current picklist or tote ID';
    }
    case 'MISSING_ORDER_ID': {
      return 'The station is missing the current order ID';
    }
    case 'MISSING_PICKLIST_ID': {
      return 'The station is missing the current picklist ID';
    }
    case 'STATION_ALREADY_PRINTING_LABEL': {
      return 'The station is already printing a label.';
    }
    case 'MISSING_CURRENT_AUTOSTORE_TASK_ID': {
      return 'The port is missing the current autostore task ID to continue operations';
    }
    case 'DUPLICATED_COMPLETED_PICKLIST': {
      return 'This picklist has already been completed and this is likely a duplicate. Please place this tote aside and alert your manager.';
    }
    case 'UNKNOWN_ORDER_ID': {
      return 'The entered order ID could not be found';
    }
    case 'TOTE_CONTAINS_RESOLUTION_PICKLIST': {
      const { toteId } = commandResult;
      return `Please transfer Tote #${toteId} to the troubleshoot station.`;
    }
    case 'TOTE_ALREADY_SCANNED': {
      const { stationId } = commandResult;
      return `This tote has already been scanned. Please return to Pack Station ${stationId}.`;
    }
    case 'TRANSFER_ID_MISMATCH': {
      const { commandTransferId, portTransferId } = commandResult;
      return `This port is serving for Transfer ${portTransferId} while you are putting away items for Transfer ${commandTransferId}`;
    }
    case 'BARCODE_DOES_NOT_MATCH_ANY_RETAIL_UNIT': {
      const { barcode, transferId } = commandResult;
      return `This Barcode ${barcode} is not found in the Transfer ${transferId}`;
    }
    case 'BARCODE_IS_DIFFERENT_FROM_BIN_ITEM': {
      const { actualBarcode } = commandResult;
      return `Scanned barcode ${actualBarcode} is different from the existing barcode in the bin`;
    }
    case 'PARTITION_NOT_FOUND': {
      return 'Invalid bin partition';
    }
    case 'PORT_NOT_FOUND': {
      return 'Port not found in system';
    }
    case 'PUTAWAY_UNCHANGED': {
      return 'The quantity is the same';
    }
    case 'INVALID_LOCATION_ID_FORMAT': {
      const { locationId } = commandResult;
      return `Invalid location ID format for ${locationId}`;
    }
    case 'LOCATION_NOT_FOUND': {
      const { locationId } = commandResult;
      return `Location ${locationId} could not be found`;
    }
    case 'PACKING_STATION_NOT_FOUND': {
      return 'Packing station not found';
    }
    case 'FULFILMENT_ORDER_NOT_FOUND': {
      return `Fulfilment order not found`;
    }
    case 'UNEXPECTED_ERROR': {
      return `An unexpected error happened`;
    }
    case 'OVER_PICK_OCCURRED': {
      return 'An over pick has occurred. Please alert your manager.';
    }
    case 'RETRY_PUSH_TOTE_TO_CONVEYOR_FAILED': {
      return 'Attempt to push tote to conveyor failed. Please alert your manager.';
    }
    case 'INSERT_NOT_REQUIRED_FOR_MERCHANT': {
      return 'Insert is not required for this merchant';
    }
    case 'INSERT_OPTION_NOT_SELECTED': {
      return 'Please make sure the inserts are added or select the “No insert” option if there are none available.';
    }
    case 'INVALID_FULFILMENT_ORDER_STATUS': {
      return 'Operation can not be completed due to invalid order status.';
    }
    case 'TOTE_ID_NOT_FOUND':
    case 'INVALID_PACKING_STATION_STATUS':
    case 'WAREHOUSE_NOT_FOUND':
    case 'LABEL_GENERATION_FAILED':
    case 'SAVE_LABEL_FAILED':
    case 'PRINTER_NOT_FOUND':
    case 'PICKLIST_ID_NOT_FOUND':
    case 'TASK_GROUP_MISSING_BINS':
    case 'WAREHOUSE_WITH_AUTOSTORE_NOT_FOUND':
    case 'ORDER_PACKING_CANCELLATION_REASONS_NOT_PROVIDED': {
      //TODO(WMS-1479): client will subscribe to event and render error message instead
      return 'An unexpected error happened`';
    }
    case 'PUSH_COMPLETED_TOTES_JOB_ENQUEUE_FAILED': {
      // eslint-disable-next-line todo-plz/ticket-ref
      //TODO(WMS-1479) client will subscribe to event and render error message instead
      return 'Failed to enqueue push completed totes job`';
    }
    case 'CLOSE_PICK_PORT_JOB_ENQUEUE_FAILED': {
      // eslint-disable-next-line todo-plz/ticket-ref
      //TODO(WMS-1479) client will subscribe to event and render error message instead
      return 'Failed to enqueue close pick port job`';
    }
    case 'TOTE_NOT_FOUND': {
      return 'The scanned tote could not be found. Please alert your manager.';
    }
    case 'BLIND_COUNT_TOTAL_ITEM_QUANTITY_INVALID': {
      return 'An issue has occurred during the blind count process. Please refresh and try again, and alert your manager if issue persists';
    }
    case 'INVALID_PICK_ITEM_BARCODE': {
      const { expectedBarcode, scannedBarcode } = commandResult;
      return `Scanned barcode ${scannedBarcode} does not match expected barcode ${expectedBarcode}`;
    }
    case 'NO_CURRENT_PICK_ASSIGNED_TO_PORT': {
      return 'No current pick assigned to port';
    }
    case 'UNABLE_TO_GET_AUTOSTORE_PORT_STATUS': {
      return 'There was an error communicating to the Autostore';
    }
    case 'PORT_ALREADY_OPENED_WITH_TASK_ASSIGNED': {
      return `The port is already opened in the Autostore with a taskID [${commandResult.taskId}] assigned`;
    }
    case 'INTERMEDIARY_STATION_NOT_FOUND': {
      return 'Cannot find intermediary station';
    }
    case 'INVALID_INTERMEDIARY_STATION_STATUS': {
      return 'Operation can not be completed due to invalid station status';
    }
    case 'NO_FULFILMENT_ORDER_AT_INTERMEDIARY_STATION': {
      return 'No fulfilment order can be found at this intermediary station';
    }
    case 'NO_TOTE_AT_INTERMEDIARY_STATION': {
      return 'No tote can be found at this intermediary station';
    }
    case 'TOTE_NOT_ON_CONVEYOR': {
      return 'Tote can not be found on the conveyor system';
    }
    case 'UNABLE_TO_GET_CONVEYOR_BAY_STATE':
    case 'UNABLE_TO_GET_CONVEYOR_TOTE_STATE':
    case 'UNABLE_TO_UPDATE_TOTE_ZONE':
    case 'UNABLE_TO_COMPLETE_TOTE_ZONE': {
      return 'There was an issue connecting to the conveyor system';
    }
    case 'UNEXPECTED_TOTE_AT_INTERMEDIARY_STATION': {
      return 'Unexpected tote in conveyor bay';
    }
    case 'REPLENISHMENT_MODE_ALREADY_SELECTED': {
      return 'Replenishment mode is already set';
    }
    case 'GET_NEXT_ITEM_JOB_ENQUEUE_FAILED': {
      return 'Failed to begin the next pick';
    }
    default: {
      return isNever(commandResult);
    }
  }
};
