"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transferEventSchema = exports.transferArrivedSchema = exports.transferCancelledSchema = exports.transferShippedSchema = exports.transferCreatedSchema = exports.transferScannedSchema = void 0;
const zod_1 = require("zod");
const base_1 = require("./base");
exports.transferScannedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SCANNED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        location: zod_1.z.object({
            portId: zod_1.z.number().int().positive(),
        }),
        putawayMode: base_1.putawayModeSchema.optional(),
    }),
});
exports.transferCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CREATED'),
    version: zod_1.z.literal(2).default(2),
    transferId: zod_1.z.string().min(1),
    organisationId: zod_1.z.string().min(1),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
        items: zod_1.z
            .object({
            quantity: zod_1.z.number().int().nonnegative(),
            quantityPutaway: zod_1.z.number().int().nonnegative().optional(),
            retailUnit: zod_1.z.object({
                id: zod_1.z.string().min(1),
                externalId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
                merchantId: zod_1.z.string().min(1),
                barcode: zod_1.z.string().min(1),
                sku: zod_1.z.string().min(1).optional(),
                name: zod_1.z.string().min(1).optional(),
                heightMm: zod_1.z.number().int().nonnegative().optional(),
                widthMm: zod_1.z.number().int().nonnegative().optional(),
                depthMm: zod_1.z.number().int().nonnegative().optional(),
                weightGrams: zod_1.z.number().int().positive(),
                options: zod_1.z.array(zod_1.z.string()).optional(),
                imageSrc: zod_1.z.string().min(1).optional(),
            }),
        })
            .array(),
        externalId: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).optional(),
        shipment: zod_1.z
            .object({
            eta: zod_1.z.union([zod_1.z.string(), zod_1.z.date()]),
            trackingNumber: zod_1.z.string(),
            carrier: zod_1.z.string(),
        })
            // TODO(WMS-735): address orphaned todo
            // eslint-disable-next-line todo-plz/ticket-ref
            // TODO: ideally this would be required, but for versioning sake it isn't
            .optional(),
        notes: zod_1.z.string().optional(),
        isTest: zod_1.z.boolean().optional(),
        labelPath: base_1.LabelPaths.optional(),
        //TODO(SSP-4764): Remove merchantId once downstream is updated to organisationId for compatibility
        merchantId: zod_1.z.string().optional(),
        organisationId: zod_1.z.string(),
        integrations: zod_1.z
            .object({
            ssp: zod_1.z
                .object({
                id: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]),
                transferNumberRef: zod_1.z.string(),
            })
                .optional(),
        })
            .optional(),
    }),
});
exports.transferShippedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_SHIPPED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_CANCELLED'),
    payload: zod_1.z.object({
        transfer: zod_1.z.object({
            id: base_1.StringId,
        }),
    }),
});
exports.transferArrivedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TRANSFER_ARRIVED'),
    payload: zod_1.z.object({
        transferId: base_1.StringId,
    }),
});
exports.transferEventSchema = zod_1.z.union([
    exports.transferCreatedSchema,
    exports.transferArrivedSchema,
    exports.transferScannedSchema,
    exports.transferShippedSchema,
    exports.transferCancelledSchema,
]);
