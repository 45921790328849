"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pickingModesUnchangedSchema = exports.RetryPushToteToConveyorFailed = exports.InvalidPutawayPortStatus = exports.InvalidPortStatus = exports.portStatusSchema = exports.OverPickOccurred = exports.partitionNotFoundOutcomeSchema = exports.PortNotFound = exports.portOpenedWithTaskAssigned = exports.unableToGetAutostorePortStatusSchema = exports.OpenPickPortSuccess = exports.NoPickTotesReplaced = exports.NoPickTotesPushed = exports.NoPickingModesFound = exports.noToteAssignedToToteLocationSchema = exports.noPicklistAssignedSchema = exports.NoTaskGroupAssigned = exports.NoTaskAssigned = exports.NoPreviousPick = exports.NoPickAssigned = exports.NoCurrentBin = exports.InvalidPortType = exports.MissingTaskId = exports.NoTaskGroupAvailable = exports.NoReadyBins = exports.NoMatchingBins = void 0;
const zod_1 = require("zod");
const autostore_port_1 = require("../../model/autostore-port");
exports.NoMatchingBins = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_EXIST'),
});
exports.NoReadyBins = zod_1.z.object({
    outcome: zod_1.z.literal('NO_MATCHING_BINS_READY'),
});
exports.NoTaskGroupAvailable = zod_1.z.object({
    outcome: zod_1.z.literal('NO_TASK_GROUP_AVAILABLE'),
});
exports.MissingTaskId = zod_1.z.object({
    outcome: zod_1.z.literal('MISSING_TASK_ID'),
});
exports.InvalidPortType = zod_1.z.object({
    outcome: zod_1.z.literal('INVALID_PORT_TYPE'),
    actualPortType: autostore_port_1.portTypeSchema.optional(),
    expectedPortType: zod_1.z
        .union([autostore_port_1.portTypeSchema, zod_1.z.array(autostore_port_1.portTypeSchema)])
        .optional(),
});
exports.NoCurrentBin = zod_1.z.object({
    outcome: zod_1.z.literal('NO_CURRENT_BIN'),
    autostorePortBinId: zod_1.z.number().optional(),
});
exports.NoPickAssigned = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PICK_ASSIGNED'),
});
exports.NoPreviousPick = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PREVIOUS_PICK'),
});
exports.NoTaskAssigned = zod_1.z.object({
    outcome: zod_1.z.literal('NO_TASK_ASSIGNED'),
});
exports.NoTaskGroupAssigned = zod_1.z.object({
    outcome: zod_1.z.literal('NO_TASK_GROUP_ASSIGNED'),
});
exports.noPicklistAssignedSchema = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PICKLIST_ASSIGNED'),
    toteLocationsWithoutPicklist: zod_1.z.array(autostore_port_1.toteLocationWithHydratedPicklistSchema),
});
exports.noToteAssignedToToteLocationSchema = zod_1.z.object({
    outcome: zod_1.z.literal('NO_TOTE_ASSIGNED_TO_TOTE_LOCATION'),
    toteLocationsWithoutToteId: zod_1.z.array(autostore_port_1.toteLocationWithHydratedPicklistSchema),
});
exports.NoPickingModesFound = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PICKING_MODES_FOUND'),
});
exports.NoPickTotesPushed = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PICK_TOTES_PUSHED'),
});
exports.NoPickTotesReplaced = zod_1.z.object({
    outcome: zod_1.z.literal('NO_PICK_TOTES_REPLACED'),
});
exports.OpenPickPortSuccess = zod_1.z.object({
    outcome: zod_1.z.literal('SUCCESS'),
    pickingModes: autostore_port_1.pickingModeStateSchema.array(),
});
exports.unableToGetAutostorePortStatusSchema = zod_1.z.object({
    outcome: zod_1.z.literal('UNABLE_TO_GET_AUTOSTORE_PORT_STATUS'),
});
exports.portOpenedWithTaskAssigned = zod_1.z.object({
    outcome: zod_1.z.literal('PORT_ALREADY_OPENED_WITH_TASK_ASSIGNED'),
    currentPortStatus: zod_1.z.union([zod_1.z.literal('OPEN'), zod_1.z.literal('CLOSED')]),
    expectedPortStatus: zod_1.z.literal('CLOSED'),
    taskId: zod_1.z.number(),
});
exports.PortNotFound = zod_1.z.object({
    outcome: zod_1.z.literal('PORT_NOT_FOUND'),
});
exports.partitionNotFoundOutcomeSchema = zod_1.z.object({
    outcome: zod_1.z.literal('PARTITION_NOT_FOUND'),
    binId: zod_1.z.string().optional(),
    partitionNumber: zod_1.z.number().optional(),
});
exports.OverPickOccurred = zod_1.z.object({
    outcome: zod_1.z.literal('OVER_PICK_OCCURRED'),
    overPickedToteLocations: zod_1.z
        .array(autostore_port_1.toteLocationWithHydratedPicklistSchema)
        .optional(),
});
exports.portStatusSchema = zod_1.z.union([
    autostore_port_1.putawayPortStatusSchema,
    autostore_port_1.PickPortStatus,
]);
exports.InvalidPortStatus = zod_1.z.object({
    outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
    currentPortStatus: exports.portStatusSchema,
    expectedPortStatus: zod_1.z.union([exports.portStatusSchema, zod_1.z.array(exports.portStatusSchema)]),
});
exports.InvalidPutawayPortStatus = zod_1.z.object({
    outcome: zod_1.z.literal('INVALID_PORT_STATUS'),
    currentPortStatus: autostore_port_1.putawayPortStatusSchema,
    expectedPortStatus: zod_1.z.union([
        autostore_port_1.putawayPortStatusSchema,
        zod_1.z.array(autostore_port_1.putawayPortStatusSchema),
    ]),
});
exports.RetryPushToteToConveyorFailed = zod_1.z.object({
    outcome: zod_1.z.literal('RETRY_PUSH_TOTE_TO_CONVEYOR_FAILED'),
});
exports.pickingModesUnchangedSchema = zod_1.z.object({
    outcome: zod_1.z.literal('PICKING_MODES_UNCHANGED'),
    pickingModeNames: autostore_port_1.pickingModeNameStateSchema.array(),
});
