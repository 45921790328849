"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortEvent = exports.selectReturnsRestockingMerchantFailedSchema = exports.returnsRestockingMerchantSelectedSchema = exports.closePickPortFailed = exports.releaseChannelResetOnPortSchema = exports.releaseChannelAssignedToPortSchema = exports.autostorePortErrorClearedSchema = exports.autostorePortErrorRaisedSchema = exports.autostorePortOpenedSchema = exports.autostorePortClosedSchema = exports.stationUnlockedSchema = exports.stationLockedSchema = exports.retryCloseBinSucceededSchema = exports.closeBinFailedSchema = exports.AutostorePortsAdded = exports.autostorePortsCreatedSchema = exports.portConfigurationChangedSchema = void 0;
const zod_1 = require("zod");
const autostore_port_1 = require("../model/autostore-port");
const featureFlags_1 = require("../model/featureFlags");
const base_1 = require("./base");
exports.portConfigurationChangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('PORT_CONFIGURATION_CHANGED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        type: autostore_port_1.portTypeSchema,
        toteLocationCount: zod_1.z.number().int().positive().optional(),
    }),
});
exports.autostorePortsCreatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORTS_CREATED'),
    payload: zod_1.z.object({
        portsCreated: zod_1.z.array(zod_1.z.number()).optional(),
    }),
});
exports.AutostorePortsAdded = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORTS_ADDED'),
    payload: zod_1.z.object({
        portIds: zod_1.z.array(zod_1.z.number()),
    }),
});
exports.closeBinFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_BIN_FAILED'),
    payload: zod_1.z.object({
        autostoreBinId: zod_1.z.number(),
        binId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
    }),
});
exports.retryCloseBinSucceededSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RETRY_CLOSE_BIN_SUCCEEDED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.stationLockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STATION_LOCKED'),
    payload: zod_1.z.object({
        type: autostore_port_1.portTypeSchema,
        portId: base_1.portIdSchema,
        reason: autostore_port_1.LockReason.optional(),
        picklistIds: zod_1.z.array(base_1.StringId).optional(),
    }),
});
exports.stationUnlockedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STATION_UNLOCKED'),
    payload: zod_1.z.object({
        type: autostore_port_1.portTypeSchema,
        portId: base_1.portIdSchema,
    }),
});
exports.autostorePortClosedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORT_CLOSED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.autostorePortOpenedSchema = base_1.eventBaseSchema.extend({
    version: zod_1.z.literal(2).default(2),
    type: zod_1.z.literal('AUTOSTORE_PORT_OPENED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        pickingModes: base_1.pickingModeEventSchema.array(),
        pickingModesChanged: zod_1.z.boolean(),
    }),
});
exports.autostorePortErrorRaisedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORT_ERROR_RAISED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.autostorePortErrorClearedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('AUTOSTORE_PORT_ERROR_CLEARED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.releaseChannelAssignedToPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_ASSIGNED_TO_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        releaseChannel: featureFlags_1.releaseChannel,
    }),
});
exports.releaseChannelResetOnPortSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('RELEASE_CHANNEL_RESET_ON_PORT'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
    }),
});
exports.closePickPortFailed = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CLOSE_PICK_PORT_FAILED'),
    payload: zod_1.z.object({
        portId: base_1.portIdSchema,
        reason: zod_1.z.union([
            zod_1.z.literal('PORT_NOT_FOUND'),
            zod_1.z.literal('INVALID_PORT_TYPE'),
            zod_1.z.literal('INVALID_PORT_STATUS'),
        ]),
    }),
});
exports.returnsRestockingMerchantSelectedSchema = base_1.portEventBaseSchema.extend({
    type: zod_1.z.literal('RETURNS_RESTOCKING_MERCHANT_SELECTED'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
    }),
});
exports.selectReturnsRestockingMerchantFailedSchema = base_1.portEventBaseSchema.extend({
    type: zod_1.z.literal('SELECT_RETURNS_RESTOCKING_MERCHANT_FAILED'),
    payload: zod_1.z.object({
        merchantId: zod_1.z.string().min(1),
        portId: base_1.portIdSchema,
        reason: zod_1.z.discriminatedUnion('outcome', [
            zod_1.z.object({ outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND') }),
            zod_1.z.object({ outcome: zod_1.z.literal('MERCHANT_NOT_FOUND') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_ELIGIBLE_FOR_RETURNS_RESTOCKING'),
            }),
            zod_1.z.object({ outcome: zod_1.z.literal('INVALID_PORT_STATUS') }),
        ]),
    }),
});
exports.PortEvent = zod_1.z.union([
    exports.portConfigurationChangedSchema,
    exports.autostorePortsCreatedSchema,
    exports.retryCloseBinSucceededSchema,
    exports.closeBinFailedSchema,
    exports.stationLockedSchema,
    exports.stationUnlockedSchema,
    exports.autostorePortClosedSchema,
    exports.autostorePortOpenedSchema,
    exports.AutostorePortsAdded,
    exports.releaseChannelAssignedToPortSchema,
    exports.releaseChannelResetOnPortSchema,
    exports.closePickPortFailed,
    exports.autostorePortErrorRaisedSchema,
    exports.autostorePortErrorClearedSchema,
    exports.returnsRestockingMerchantSelectedSchema,
    exports.selectReturnsRestockingMerchantFailedSchema,
]);
