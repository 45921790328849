"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WarehouseStateChangedSocketEvents = exports.WAREHOUSE_PICK_SOCKET_EVENT_NAMES = exports.PICK_SOCKET_EVENT_NAMES = exports.GlobalPickSocketEvent = exports.PickSocketEvent = exports.PUTAWAY_SOCKET_EVENT_NAMES = exports.PutawaySocketEvent = void 0;
const zod_1 = require("zod");
const pick_1 = require("./pick");
const port_1 = require("./port");
const putaway_1 = require("./putaway");
const storage_1 = require("./storage");
const transfer_1 = require("./transfer");
const warehouse_1 = require("./warehouse");
exports.PutawaySocketEvent = zod_1.z.union([
    transfer_1.transferScannedSchema,
    port_1.portConfigurationChangedSchema,
    putaway_1.putawayPortOpenedSchema,
    putaway_1.PutawayCompleted,
    putaway_1.putawayItemAddedSchema,
    putaway_1.putawayItemRemovedSchema,
    putaway_1.putawayBinReturnedSchema,
    pick_1.binArrivedSchema,
    pick_1.binRequestedSchema,
    putaway_1.putawayBinRequestedSchema,
    pick_1.BinRetrievalTimedOut,
    putaway_1.partitionsConfiguredSchema,
    storage_1.putawayItemsPlacedInPartitionSchema,
    storage_1.putawayItemsRemovedFromPartitionSchema,
    pick_1.pickBinReturnedSchema,
    putaway_1.replenishmentModeSelectedSchema,
]);
exports.PUTAWAY_SOCKET_EVENT_NAMES = exports.PutawaySocketEvent.options.map((opt) => opt.shape.type.value);
exports.PickSocketEvent = zod_1.z.union([
    pick_1.pickPortOpeningSchema,
    pick_1.pickPortOpenedSchema,
    pick_1.pickPortOpeningFailedSchema,
    pick_1.pickPortClosingSchema,
    pick_1.pickPortClosedSchema,
    pick_1.pickPortClosingFailedSchema,
    pick_1.binArrivedSchema,
    pick_1.binRequestedSchema,
    pick_1.BinRetrievalTimedOut,
    pick_1.pickPortTotesPlacedSchema,
    pick_1.toteLocationScanSucceededSchema,
    pick_1.toteScanSucceededSchema,
    pick_1.toteLinkConfirmedSchema,
    pick_1.toteLinkCancelledSchema,
    pick_1.noTaskGroupsAvailableSchema,
    pick_1.PickingStarted,
    pick_1.PickingFailedToStart,
    pick_1.itemReadyToPickSchema,
    pick_1.allTotesUnlinkedSchema,
    pick_1.pickCompletedSchema,
    pick_1.missingItemConfirmedSchema,
    pick_1.missingItemAtPickingDetectedSchema,
    pick_1.missingItemResolutionCancelledSchema,
    pick_1.totesPickingCompletedSchema,
    pick_1.LastPickUndone,
    pick_1.pickTotesPushedSchema,
    pick_1.pickTotesReplacedSchema,
    pick_1.pickTotesUnpushedSchema,
    pick_1.pickPortTaskGroupAssignmentStoppedSchema,
    pick_1.PickLocationsClosed,
    pick_1.unexpectedBinRetrievedSchema,
    pick_1.shortPickRaisedSchema,
    pick_1.redPaperPlacedInTotesSchema,
    pick_1.GreenPaperPlacedInTotes,
    port_1.closeBinFailedSchema,
    port_1.retryCloseBinSucceededSchema,
    port_1.stationLockedSchema,
    port_1.stationUnlockedSchema,
    pick_1.pickBinReturnedSchema,
    pick_1.zeroCountCrossCheckRequiredSchema,
    pick_1.pickItemAddedToToteSchema,
    pick_1.pickItemBarcodeValidatedSchema,
    pick_1.pickItemBarcodeValidationFailedSchema,
    pick_1.updateLinkedTotesAtPortSchema,
    pick_1.removeEmptyTotesFromPortsSchema,
    pick_1.itemsRecoveredFromUnexpectedToteExitSchema,
    port_1.autostorePortErrorRaisedSchema,
    port_1.autostorePortErrorClearedSchema,
    pick_1.pickPortPickingModesQueueClearedSchema,
    pick_1.pickPortPickingModesSelectedSchema,
]);
exports.GlobalPickSocketEvent = zod_1.z.union([
    pick_1.binArrivedSchema,
    pick_1.pickBinReturnedSchema,
]);
exports.PICK_SOCKET_EVENT_NAMES = exports.PickSocketEvent.options.map((opt) => opt.shape.type.value);
exports.WAREHOUSE_PICK_SOCKET_EVENT_NAMES = exports.GlobalPickSocketEvent.options.map((opt) => opt.shape.type.value);
exports.WarehouseStateChangedSocketEvents = zod_1.z.union([
    warehouse_1.AutostoreStarted,
    warehouse_1.AutostoreStopped,
    warehouse_1.AutostoreUnreachable,
]);
