import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Container } from '../components/admin/Container';
import { Navigation } from '../components/admin/Navigation';
import { SectionHeader } from '../components/admin/SectionHeader';
import { useWarehouseParam } from '../hooks/useWarehouseParam';
import { useWarehousesApi } from '../hooks/useWarehousesApi';
import { devLinks, mainLinks, warehouseLinks } from '../router/links';
import {
  DEVICE_PATHS,
  DEV_PATHS,
  PORT_PATHS,
  STORAGE_UNIT_PATHS,
  WAREHOUSE_PATHS,
} from '../router/paths';
import { AddWarehouse } from '../screens/admin/AddWarehouse';
import { AdminHome } from '../screens/admin/AdminHome';
import { Auth } from '../screens/admin/Auth';
import { Devices } from '../screens/admin/Devices';
import { FulfilmentOrder } from '../screens/admin/devScreens/FulfilmentOrder/FulfilmentOrder';
import { EditWarehouse } from '../screens/admin/EditWarehouse';
import { PortsOverview } from '../screens/admin/PortsOverview';
import { StorageUnitAddAutoStore } from '../screens/admin/StorageUnitAddAutoStore';
import { AddOrderTote } from '../screens/admin/StorageUnitAddOrderTote';
import { StorageUnitCreated } from '../screens/admin/StorageUnitCreated';
import { StorageUnitSetup } from '../screens/admin/StorageUnitSetup';
import { WarehouseOverview } from '../screens/admin/WarehouseOverview';
import { AdminThemeProvider } from '../themes/AdminTheme';
import { generatePathWithWarehouseId } from '../utils/generatePathWithWarehouseId';
import { useNavStyles } from './styles';

const TempComingSoon = () => {
  useWarehouseParam();
  return (
    <Container>
      <SectionHeader>Coming Soon!</SectionHeader>
    </Container>
  );
};

const StorageUnits = () => {
  return (
    <>
      <SectionHeader>Storage units</SectionHeader>
      <Container>
        <Switch>
          <Route path={WAREHOUSE_PATHS.STORAGE_UNITS} exact>
            <StorageUnitSetup />
          </Route>
          <Route path={STORAGE_UNIT_PATHS.AUTOSTORE}>
            <StorageUnitAddAutoStore />
          </Route>
          <Route
            path={STORAGE_UNIT_PATHS.STORAGE_CREATED}
            component={StorageUnitCreated}
          />
          <Route
            path={STORAGE_UNIT_PATHS.ISLE_STORAGE}
            component={TempComingSoon}
          />
          <Route
            path={STORAGE_UNIT_PATHS.FLOOR_STORAGE}
            component={TempComingSoon}
          />
          <Route
            path={STORAGE_UNIT_PATHS.ORDER_TOTE}
            component={AddOrderTote}
          />
          <Route
            path={STORAGE_UNIT_PATHS.CMC_TOTE}
            component={TempComingSoon}
          />
        </Switch>
      </Container>
    </>
  );
};

function AdminAppInner() {
  const [status, refreshWarehouses] = useWarehousesApi();
  const classes = useNavStyles();
  const history = useHistory();

  const onWarehouseAdded = () => {
    refreshWarehouses();
    history.push('/admin');
  };
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" noWrap>
            Waretopia WMS
          </Typography>
        </Toolbar>
      </AppBar>
      <Navigation
        navigationLinks={mainLinks}
        settingsLinks={warehouseLinks}
        devLinks={devLinks}
        navigate={(path, state) =>
          history.push(generatePathWithWarehouseId(path, state))
        }
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route path="/admin/:warehouseId?" exact>
          <AdminHome onRefreshClick={refreshWarehouses} status={status} />
        </Route>
        <Route path="/admin/warehouse/:warehouseId?/overview" exact>
          <WarehouseOverview />
        </Route>
        <Route path={WAREHOUSE_PATHS.EDIT_WAREHOUSE}>
          <EditWarehouse />
        </Route>
        <Route path="/admin/add-warehouse">
          <AddWarehouse onSubmit={onWarehouseAdded} />
        </Route>
        <Route path="/admin/warehouse/:warehouseId?/storage-units">
          <StorageUnits />
        </Route>
        <Route path={DEVICE_PATHS.OVERVIEW}>
          <Devices />
        </Route>
        <Route path={PORT_PATHS.OVERVIEW} component={PortsOverview} />
        {/* TODO: if in dev env, add routes for dev extras */}
        <Route path={DEV_PATHS.FULFILMENT_ORDER} component={FulfilmentOrder} />
        <Route path={DEV_PATHS.AUTH} component={Auth} />
      </main>
    </div>
  );
}

function AdminApp() {
  return (
    <AdminThemeProvider>
      <AdminAppInner />
    </AdminThemeProvider>
  );
}

export { AdminApp };
