"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.packingStationApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const packAndDispatch_1 = require("../commands/outcomes/packAndDispatch");
const packAndDispatch_2 = require("../model/packAndDispatch");
exports.packingStationApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /warehouses/:warehouseId/packing-stations': {
        responseBody: zod_http_schemas_1.z.object({
            outcome: zod_http_schemas_1.z.literal('SUCCESS'),
            packingStations: zod_http_schemas_1.z.array(packAndDispatch_2.PackAndDispatchStateModel),
        }),
    },
    'POST /warehouses/:warehouseId/packing-stations/:packingStationId/bail-out': {
        responseBody: packAndDispatch_1.bailOutPackingStationOutcomeSchema,
    },
});
