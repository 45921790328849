import { PickingMode } from 'api-schema/lib/model/autostore-port';
import {
  PortPickingModeChip,
  PortPickingModeChipVariant,
} from '../../../../components/warehouse/PortPickingMode/PortPickingModeChip';
import { Text } from '../../../../components/warehouse/Text';
import { Wrapper } from './PickingModePanel.elements';

export type PickingModePanelProps = {
  pickingModes: Array<PickingMode>;
  queuedPickingModes: Array<PickingMode['name']> | undefined;
};

const PICKING_MODE_NAME_LABEL: Record<PickingMode['name'], string> = {
  B2B: 'B2B',
  STANDARD: 'Standard',
  PRIORITY: 'Priority',
  SAMEDAY: 'Same Day',
  MANUAL_PACK: 'Manual Pack',
  STOCK_REMOVAL: 'Stock Removal',
};

type PickingModeChipDetails = {
  friendlyName: string;
  chip: PortPickingModeChipVariant;
};

const TransitioningChip = ({
  pickingModes,
}: {
  pickingModes: Array<PickingMode['name']>;
}) => {
  const transitioningPickingModeLabels = pickingModes.map(
    (pickingModeName) => PICKING_MODE_NAME_LABEL[pickingModeName]
  );

  if (transitioningPickingModeLabels.length === 2) {
    return (
      <PortPickingModeChip variant="TRANSITIONING">
        <Text tag="span">
          Transitioning to{' '}
          <Text weight="medium" tag="span">
            {transitioningPickingModeLabels[0]}
          </Text>{' '}
          and{' '}
          <Text weight="medium" tag="span">
            {transitioningPickingModeLabels[1]}
          </Text>{' '}
          modes
        </Text>
      </PortPickingModeChip>
    );
  }

  return (
    <PortPickingModeChip variant="TRANSITIONING">
      <Text tag="span">
        Transitioning to{' '}
        <Text weight="medium" tag="span">
          {transitioningPickingModeLabels[0]}
        </Text>{' '}
        mode
      </Text>
    </PortPickingModeChip>
  );
};

export function PickingModePanel({
  pickingModes,
  queuedPickingModes,
}: PickingModePanelProps) {
  if (queuedPickingModes?.length) {
    return <TransitioningChip pickingModes={queuedPickingModes} />;
  }

  const friendlyPickingModeNames: Array<PickingModeChipDetails> =
    pickingModes.map((pickingMode) => ({
      chip: pickingMode.name,
      friendlyName: PICKING_MODE_NAME_LABEL[pickingMode.name],
    }));

  return (
    <Wrapper>
      {friendlyPickingModeNames.map((pickingMode) => (
        <PortPickingModeChip variant={pickingMode.chip}>
          <Text tag="span">{pickingMode.friendlyName} mode</Text>
        </PortPickingModeChip>
      ))}
    </Wrapper>
  );
}
