"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retailUnitApiSchema = exports.systemApiSchema = exports.merchantApiSchema = exports.spectrumWcsApiSchema = exports.messageQueueApiSchema = exports.inventoryApiSchema = exports.featureFlagApiSchema = exports.storageUnitApiSchema = exports.picklistApiSchema = exports.apiHttpClient = exports.combinedApiSchema = exports.intermediaryStationApiSchema = exports.fulfilmentOrderApiSchema = exports.warehouseApiSchema = exports.cmcGenesysApiSchema = exports.webhookApiSchema = void 0;
const zod_http_schemas_1 = require("zod-http-schemas");
const client_1 = require("zod-http-schemas/client");
const messageQueue_1 = require("./backoffice/messageQueue");
Object.defineProperty(exports, "messageQueueApiSchema", { enumerable: true, get: function () { return messageQueue_1.messageQueueApiSchema; } });
const featureFlag_1 = require("./featureFlag");
Object.defineProperty(exports, "featureFlagApiSchema", { enumerable: true, get: function () { return featureFlag_1.featureFlagApiSchema; } });
const fulfilmentOrder_1 = require("./fulfilmentOrder");
Object.defineProperty(exports, "fulfilmentOrderApiSchema", { enumerable: true, get: function () { return fulfilmentOrder_1.fulfilmentOrderApiSchema; } });
const fulfilmentOrderSummary_1 = require("./fulfilmentOrderSummary");
const intermediaryStation_1 = require("./intermediaryStation");
Object.defineProperty(exports, "intermediaryStationApiSchema", { enumerable: true, get: function () { return intermediaryStation_1.intermediaryStationApiSchema; } });
const inventory_1 = require("./inventory");
Object.defineProperty(exports, "inventoryApiSchema", { enumerable: true, get: function () { return inventory_1.inventoryApiSchema; } });
const merchant_1 = require("./merchant");
Object.defineProperty(exports, "merchantApiSchema", { enumerable: true, get: function () { return merchant_1.merchantApiSchema; } });
const packingStation_1 = require("./packingStation");
const picklists_1 = require("./picklists");
Object.defineProperty(exports, "picklistApiSchema", { enumerable: true, get: function () { return picklists_1.picklistApiSchema; } });
const retailUnit_1 = require("./retailUnit");
Object.defineProperty(exports, "retailUnitApiSchema", { enumerable: true, get: function () { return retailUnit_1.retailUnitApiSchema; } });
const commands_1 = require("./spectrum/commands");
Object.defineProperty(exports, "spectrumWcsApiSchema", { enumerable: true, get: function () { return commands_1.spectrumWcsApiSchema; } });
const webhook_1 = require("./spectrum/webhook");
const storageUnit_1 = require("./storageUnit");
Object.defineProperty(exports, "storageUnitApiSchema", { enumerable: true, get: function () { return storageUnit_1.storageUnitApiSchema; } });
const system_1 = require("./system");
Object.defineProperty(exports, "systemApiSchema", { enumerable: true, get: function () { return system_1.systemApiSchema; } });
const warehouse_1 = require("./warehouse");
Object.defineProperty(exports, "warehouseApiSchema", { enumerable: true, get: function () { return warehouse_1.warehouseApiSchema; } });
const wcs_1 = require("./wcs");
Object.defineProperty(exports, "cmcGenesysApiSchema", { enumerable: true, get: function () { return wcs_1.cmcGenesysApiSchema; } });
const webhook_2 = require("./webhook");
Object.defineProperty(exports, "webhookApiSchema", { enumerable: true, get: function () { return webhook_2.webhookApiSchema; } });
const combinedApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    ...warehouse_1.warehouseApiSchema,
    ...fulfilmentOrder_1.fulfilmentOrderApiSchema,
    ...webhook_2.webhookApiSchema,
    ...webhook_1.spectrumWebhookApiSchema,
    ...intermediaryStation_1.intermediaryStationApiSchema,
    ...wcs_1.cmcGenesysApiSchema,
    ...picklists_1.picklistApiSchema,
    ...storageUnit_1.storageUnitApiSchema,
    ...featureFlag_1.featureFlagApiSchema,
    ...inventory_1.inventoryApiSchema,
    ...messageQueue_1.messageQueueApiSchema,
    ...commands_1.spectrumWcsApiSchema,
    ...merchant_1.merchantApiSchema,
    ...fulfilmentOrderSummary_1.fulfilmentOrderSummaryApiSchema,
    ...system_1.systemApiSchema,
    ...retailUnit_1.retailUnitApiSchema,
    ...packingStation_1.packingStationApiSchema,
});
exports.combinedApiSchema = combinedApiSchema;
const apiHttpClient = (baseURL, authToken) => (0, client_1.createHttpClient)(combinedApiSchema, {
    baseURL,
    headers: authToken
        ? {
            Authorization: `Bearer ${authToken}`,
        }
        : undefined,
});
exports.apiHttpClient = apiHttpClient;
