"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FulfilmentOrderEvent = exports.cmcGenesysParcelTransferredToTroubleshoot = exports.toteSentToTroubleshootingFromCmcSchema = exports.orderReturnedToPackingQueueSchema = exports.staleOrdersIdentifiedSchema = exports.orderForcedToSendToCmcGenesysSchema = exports.orderManuallyMarkedAsPackedSchema = exports.orderItemsUnallocatedSchema = exports.FulfilmentOrdersAutostoreHandPickingStarted = exports.HandPickingFulfilmentOrdersReverted = exports.FulfilmentOrdersSelectedForAutostoreHandlPicking = exports.cmcGenesysTroubleshootLabelPrintedSchema = exports.cmcGenesysTroubleshootLabelPrintingFailedSchema = exports.cmcGenesysTroubleshootLabelPrintingSchema = exports.cmcGenesysParcelLabelledSuccessfullySchema = exports.cmcGenesysParcelLabelNotFound = exports.cmcGenesysParcelRejectedAtLabellerSchema = exports.cmcGenesysBadParcelProducedSchema = exports.cmcGenesysGoodParcelProducedSchema = exports.cmcGenesysToteExitedInFailureSchema = exports.cmcGenesysParcelMeasuredFailedSchema = exports.cmcGenesysParcelRemovedBeforeExitSchema = exports.cmcGenesysParcelMeasuredSchema = exports.cmcGenesysToteScannedSchema = exports.cmcGenesysOrderAlreadyPackedSchema = exports.cmcGenesysIneligibleOrderDetectedSchema = exports.cmcGenesysParcelValidationFailedSchema = exports.cmcGenesysToteValidationFailedSchema = exports.FulfilmentOrderCancelRejected = exports.fulfilmentOrderCancelledSchema = exports.fulfilmentOrderManuallyDispatchedSchema = exports.fulfilmentOrderDispatchedSchema = exports.shippingRateSelectedSchema = exports.fulfilmentOrderRateRequestFailedSchema = exports.fulfilmentOrderRatesRetrievedSchema = exports.fulfilmentOrderRejectedSchema = exports.fulfilmentOrderDeletedSchema = exports.fulfilmentOrderLineItemsChangeFailedSchema = exports.nonFulfillableLineItemsOutcomesSchema = exports.fulfilmentOrderLineItemsChangedSchema = exports.fulfilmentOrderTagsChangedSchema = exports.fulfilmentOrderAcceptedSchema = void 0;
const zod_1 = require("zod");
const cmc_genesys_1 = require("../commands/outcomes/cmc-genesys");
const taskGroups_1 = require("../commands/outcomes/taskGroups");
const address_1 = require("../constants/address");
const wcs_1 = require("../http/wcs");
const model_1 = require("../model");
const base_1 = require("./base");
const fulfilmentOrderStatusSchema = zod_1.z.union([
    zod_1.z.literal('ACCEPTED'),
    zod_1.z.literal('AWAITING_RATE_SELECTION'),
    zod_1.z.literal('REJECTED'),
    zod_1.z.literal('AWAITING_ASSIGNMENT'),
    zod_1.z.literal('SELECTED_FOR_AUTOSTORE_HAND_PICKING'),
    zod_1.z.literal('ASSIGNED_TO_TASK_GROUP'),
    zod_1.z.literal('PICKING'),
    zod_1.z.literal('AUTOSTORE_HAND_PICKING'),
    zod_1.z.literal('AWAITING_PACKING'),
    zod_1.z.literal('PACKING'),
    zod_1.z.literal('PACKED'),
    zod_1.z.literal('SHIPPED'),
    zod_1.z.literal('TROUBLESHOOTING'),
    zod_1.z.literal('CANCELLED'),
]);
const shippingMethod = zod_1.z.union([zod_1.z.literal('STANDARD'), zod_1.z.literal('SAMEDAY')]);
const carrierName = zod_1.z.union([zod_1.z.string(), zod_1.z.literal('SKUTOPIA')]);
const FulfilmentOrder = zod_1.z.object({
    id: zod_1.z.string(),
    externalFulfilmentId: zod_1.z.string().optional(),
    externalOrderReference: zod_1.z.string().optional(),
    merchantId: zod_1.z.string().min(1),
    merchantName: zod_1.z.string().min(1).optional(),
    isTest: zod_1.z.boolean().optional(),
    isFcFulfillable: zod_1.z.boolean().optional(),
    lineItems: zod_1.z.array(zod_1.z.object({
        barcode: zod_1.z.string(),
        quantity: zod_1.z.number(),
        isBundle: zod_1.z.boolean().optional(),
        isDigital: zod_1.z.boolean().optional(),
        sku: zod_1.z.string().nullish(),
    })),
    customer: zod_1.z.object({
        name: zod_1.z.string().min(1).optional(),
        phone: zod_1.z.string().min(1).optional(),
    }),
    address: zod_1.z.optional(zod_1.z.object({
        line1: zod_1.z.string().nullish(),
        line2: zod_1.z.string().nullish(),
        locality: zod_1.z.string().nullish(),
        company: zod_1.z.string().nullish(),
        countryCode: address_1.countryCodeSchema.nullish(),
        postalCode: zod_1.z.string().nullish(),
    })),
    shippingMethod,
    carrierName: carrierName.optional(),
    doesRequireManifest: zod_1.z.boolean().optional(),
    tags: zod_1.z.array(zod_1.z.string()).optional(),
    isB2B: zod_1.z.boolean().optional(),
    packingMode: model_1.packingModeSchema.optional(),
    store: zod_1.z.optional(zod_1.z.object({
        id: zod_1.z.string(),
    })),
});
const AvailableQuantity = zod_1.z.object({
    barcode: zod_1.z.string(),
    quantity: zod_1.z.number(),
    availableQuantity: zod_1.z.number(),
});
const InsufficientStockInfo = zod_1.z.object({
    warehouse: zod_1.z.object({ id: zod_1.z.string() }),
    insufficientItems: zod_1.z.array(AvailableQuantity),
});
const FulfilmentOrderRejectionReason = zod_1.z.union([
    zod_1.z.object({
        type: zod_1.z.literal('INVALID_REQUEST'),
        errors: zod_1.z.record(zod_1.z.string().array()).optional(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('UNKNOWN_BARCODES'),
        unknownBarcodes: zod_1.z.array(zod_1.z.string()),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('BARCODES_MISSING'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('BARCODES_WITH_MULTIPLE_SKUS'),
        barcodes: zod_1.z.string().array(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('WEIGHT_LIMIT_EXCEEDED'),
        weightLimitGm: zod_1.z.number(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('VOLUME_LIMIT_EXCEEDED'),
        volumeLimitMm: zod_1.z.number(),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('INSUFFICIENT_STOCK'),
        insufficientInfo: zod_1.z.array(InsufficientStockInfo),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('FC_FULFILLABLE_DISABLED'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('INVALID_LINE_ITEM_QUANTITIES'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('NO_FULFILABLE_LINE_ITEMS_FOUND'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('NO_VALID_WAREHOUSES'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('RATES_RETRIEVAL_FAILED'),
    }),
    zod_1.z.object({
        type: zod_1.z.literal('RATE_SELECTION_FAILED'),
    }),
]);
const fulfilmentOrderSchema = FulfilmentOrder.extend({
    status: fulfilmentOrderStatusSchema,
    rejectedReason: zod_1.z.optional(FulfilmentOrderRejectionReason),
    parcels: zod_1.z
        .array(zod_1.z.object({
        stationId: zod_1.z.number().optional(),
        parcelSize: zod_1.z
            .enum(['EXTRA_SMALL', 'SMALL', 'MEDIUM', 'LARGE'])
            .optional(),
        size: zod_1.z.object({
            widthMm: zod_1.z.number(),
            lengthMm: zod_1.z.number(),
            heightMm: zod_1.z.number(),
        }),
        weightGrams: zod_1.z.number().positive().optional(),
    }))
        .optional(),
    labelPaths: base_1.LabelPaths.optional(),
    tags: zod_1.z.array(zod_1.z.string()).optional(),
});
exports.fulfilmentOrderAcceptedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_ACCEPTED'),
    payload: FulfilmentOrder,
});
exports.fulfilmentOrderTagsChangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_TAGS_CHANGED'),
    payload: zod_1.z.object({
        fulfilmentOrder: zod_1.z.object({
            id: zod_1.z.string(),
            tags: zod_1.z.array(zod_1.z.string()).optional(),
            merchantId: zod_1.z.string(),
            externalFulfilmentId: zod_1.z.string().optional(),
        }),
        integrations: zod_1.z
            .object({
            ssp: zod_1.z
                .object({
                salesOrderId: zod_1.z.string().optional(),
                orderReference: zod_1.z.string().nullish(),
                storeId: zod_1.z.string().optional(),
            })
                .optional(),
        })
            .optional(),
    }),
});
const fulfilmentOrderInventoryChangeSchema = zod_1.z.object({
    barcode: model_1.barcodeSchema,
    quantity: zod_1.z.number().int().positive(),
});
exports.fulfilmentOrderLineItemsChangedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_LINE_ITEMS_CHANGED'),
    payload: zod_1.z.object({
        fulfilmentOrder: zod_1.z.object({
            id: zod_1.z.string(),
            lineItems: zod_1.z.array(zod_1.z.object({
                barcode: zod_1.z.string(),
                quantity: zod_1.z.number().nonnegative(),
                isBundle: zod_1.z.boolean().optional(),
                isDigital: zod_1.z.boolean().optional(),
            })),
            integrations: zod_1.z
                .object({
                ssp: zod_1.z
                    .object({
                    salesOrderId: zod_1.z.string().optional(),
                    fulfilmentShipmentId: zod_1.z.string(),
                    salesOrderReference: zod_1.z.string().optional(),
                })
                    .optional(),
            })
                .optional(),
            merchantId: model_1.merchantIdSchema,
        }),
        inventory: zod_1.z.object({
            added: fulfilmentOrderInventoryChangeSchema.array(),
            removed: fulfilmentOrderInventoryChangeSchema.array(),
        }),
    }),
});
const requestedLineItem = zod_1.z.object({
    name: zod_1.z.string().nullish(),
    sku: zod_1.z.string().nullish(),
    barcode: zod_1.z.string().nullish(),
    quantity: zod_1.z.number(),
    isBundle: zod_1.z.boolean(),
    isDigital: zod_1.z.boolean().optional(),
});
const nonFulfillableLineItemsOutcomeSchemas = [
    zod_1.z.object({
        outcome: zod_1.z.literal('BARCODES_MISSING'),
        invalidLineItems: requestedLineItem.array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('NO_PHYSICAL_LINE_ITEMS_FOUND'),
        invalidLineItems: requestedLineItem.array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('VOLUME_LIMIT_EXCEEDED'),
        requestedVolumeCubicMm: zod_1.z.number(),
        maximumVolumeCubicMm: zod_1.z.number(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('WEIGHT_LIMIT_EXCEEDED'),
        requestedWeightGrams: zod_1.z.number(),
        maximumWeightGrams: zod_1.z.number(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('UNKNOWN_BARCODES'),
        invalidLineItems: requestedLineItem.array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('LINE_ITEMS_WITH_INVALID_QUANTITIES'),
        invalidLineItems: zod_1.z
            .object({ barcode: zod_1.z.string(), quantity: zod_1.z.number() })
            .array(),
    }),
    zod_1.z.object({
        outcome: zod_1.z.literal('BARCODES_WITH_MULTIPLE_SKUS'),
        barcodes: zod_1.z.string().array(),
    }),
];
exports.nonFulfillableLineItemsOutcomesSchema = zod_1.z.union(nonFulfillableLineItemsOutcomeSchemas);
exports.fulfilmentOrderLineItemsChangeFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_LINE_ITEMS_CHANGE_FAILED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string(),
        reason: zod_1.z.discriminatedUnion('outcome', [
            ...nonFulfillableLineItemsOutcomeSchemas,
            zod_1.z.object({ outcome: zod_1.z.literal('UNKNOWN_FULFILMENT_ORDER') }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_ORDER_STATUS'),
                currentStatus: fulfilmentOrderStatusSchema,
                validStatuses: fulfilmentOrderStatusSchema.array(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_PICKLIST_STATUSES'),
                currentPicklistStatuses: zod_1.z
                    .object({ id: zod_1.z.string(), status: model_1.picklistStatusSchema })
                    .array()
                    .optional(),
                validPicklistStatuses: model_1.picklistStatusSchema.array().optional(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_TASK_GROUP_STATUSES'),
                currentTaskGroupStatuses: zod_1.z
                    .object({ id: zod_1.z.string(), status: model_1.taskGroupStatusSchema })
                    .array()
                    .optional(),
                validTaskGroupStatuses: model_1.taskGroupStatusSchema.array().optional(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INSUFFICIENT_STOCK_FOR_FULFILLABLE_LINE_ITEMS'),
                insufficientLineItems: zod_1.z
                    .object({
                    lineItemQuantity: zod_1.z.number(),
                    availableQuantity: zod_1.z.number(),
                    totalQuantity: zod_1.z.number(),
                })
                    .array(),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('TASK_GROUP_CANCELLATION_FAILED'),
                taskGroupCancellationFailureReason: taskGroups_1.cancelTaskGroupsErrorOutcomeSchema,
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('FC_FULFILLABLE_DISABLED'),
            }),
        ]),
        integrations: zod_1.z
            .object({
            ssp: zod_1.z
                .object({
                id: zod_1.z.string().optional(),
                fulfilmentShipmentId: zod_1.z.string(),
                orderReference: zod_1.z.string().optional(),
            })
                .optional(),
        })
            .optional(),
    }),
});
exports.fulfilmentOrderDeletedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_DELETED'),
    payload: fulfilmentOrderSchema,
});
exports.fulfilmentOrderRejectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_REJECTED'),
    payload: zod_1.z.object({
        externalFulfilmentId: zod_1.z.string().min(1),
        merchantId: zod_1.z.string().min(1),
        reason: FulfilmentOrderRejectionReason,
        id: zod_1.z.string().optional(),
        wasPreviouslyAccepted: zod_1.z.boolean().optional(),
        fulfilmentOrder: FulfilmentOrder.optional(),
    }),
});
exports.fulfilmentOrderRatesRetrievedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_RATES_RETRIEVED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string().min(1),
    }),
});
exports.fulfilmentOrderRateRequestFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_RATE_REQUEST_FAILED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string().min(1),
    }),
});
exports.shippingRateSelectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('SHIPPING_RATE_SELECTED'),
    payload: zod_1.z.object({
        shippingMethod,
        carrier: zod_1.z.string().optional(),
        serviceCode: zod_1.z.string().optional(),
        fulfilmentOrderId: zod_1.z.string(),
    }),
});
exports.fulfilmentOrderDispatchedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_DISPATCHED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string(),
        source: zod_1.z.string(),
        trackingNumber: zod_1.z.string().nullable(),
    }),
});
exports.fulfilmentOrderManuallyDispatchedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_MANUALLY_DISPATCHED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string(),
    }),
});
exports.fulfilmentOrderCancelledSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_CANCELLED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string().min(1),
        externalFulfilmentId: zod_1.z.string().min(1).optional(),
        merchantId: zod_1.z.string().min(1),
        picklistIds: zod_1.z.array(zod_1.z.string().min(1)),
        isUnfulfillable: zod_1.z.boolean().optional(),
        assignedPortId: zod_1.z.string().optional(),
        affectedPartitions: zod_1.z.array(base_1.affectedPartition).optional(),
    }),
});
const fulfilmentOrderCancelRejectionReasonSchema = zod_1.z.enum([
    'ORDER_ALREADY_ASSIGNED',
    'FAILED_TO_CANCEL_TASK_GROUPS',
]);
exports.FulfilmentOrderCancelRejected = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDER_CANCEL_REJECTED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string().min(1),
        externalFulfilmentOrderId: zod_1.z.string().min(1).optional(),
        reason: fulfilmentOrderCancelRejectionReasonSchema,
    }),
});
exports.cmcGenesysToteValidationFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TOTE_VALIDATION_FAILED'),
    payload: cmc_genesys_1.validateCmcGenesysToteErrorOutcomeSchema.and(zod_1.z.object({
        cmcEventName: zod_1.z.union([
            zod_1.z.literal('ENQ'),
            zod_1.z.literal('ACK'),
            zod_1.z.literal('OUT'),
        ]),
    })),
});
exports.cmcGenesysParcelValidationFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_VALIDATION_FAILED'),
    payload: cmc_genesys_1.validateCmcGenesysParcelErrorOutcomeSchema.and(zod_1.z.object({
        cmcEventName: zod_1.z.union([
            zod_1.z.literal('LAB'),
            zod_1.z.literal('REM'),
            zod_1.z.literal('END'),
        ]),
    })),
});
exports.cmcGenesysIneligibleOrderDetectedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_INELIGIBLE_ORDER_DETECTED'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
        referenceId: zod_1.z.string(),
        cmcEventName: zod_1.z.literal('ENQ'),
        reason: zod_1.z
            .enum([
            'ELIGIBLE',
            'ORDER_HAS_NO_SERVICE_CODE',
            'MERCHANT_NOT_ELIGIBLE',
            'SERVICE_CODE_NOT_ELIGIBLE',
            'ORDER_HAS_EXCLUDED_BARCODES',
            'ORDER_HAS_ITEMS_MISSING',
        ])
            .optional(),
    }),
});
exports.cmcGenesysOrderAlreadyPackedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_ORDER_ALREADY_PACKED'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
        cmcEventName: zod_1.z.literal('ENQ'),
    }),
});
exports.cmcGenesysToteScannedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TOTE_SCANNED'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysParcelMeasuredSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_MEASURED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        cartonMeasurements: zod_1.z.object({
            widthMm: zod_1.z.number().nonnegative(),
            heightMm: zod_1.z.number().nonnegative(),
            lengthMm: zod_1.z.number().nonnegative(),
            totalWeightGrams: zod_1.z.number().positive(),
            cartonWeightGrams: zod_1.z.number().positive(),
            estimatedParcelWeightGrams: zod_1.z.number().optional(),
            estimatedCartonWeightGrams: zod_1.z.number().optional(),
        }),
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysParcelRemovedBeforeExitSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_REMOVED_BEFORE_EXIT'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysParcelMeasuredFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_MEASURED_FAILED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        cartonMeasurements: wcs_1.CmcMeasurements,
        referenceId: zod_1.z.string(),
        reason: zod_1.z.union([
            zod_1.z.literal('NO_READ_ON_VARYTOTE'),
            zod_1.z.literal('OUT_OF_FORMAT'),
            zod_1.z.literal('EMPTY_VARYTOTE'),
            zod_1.z.literal('MISSING_ENQ_MESSAGE'),
            zod_1.z.literal('INCORRECT_ENQ_MESSAGE'),
            zod_1.z.literal('FLAGGED_FOR_REJECTION_IN_ENQ'),
            zod_1.z.literal('BAD_MEASUREMENTS'),
            zod_1.z.literal('UNMATCHED_PICKLIST_ID'),
        ]),
    }),
});
exports.cmcGenesysToteExitedInFailureSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TOTE_EXITED_IN_ERROR'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
        toteId: zod_1.z.string(),
        reason: zod_1.z.union([
            zod_1.z.literal('VARYTOTE_WITHOUT_TRACKING'),
            zod_1.z.literal('VARYTOTE_NOT_EMPTY'),
            zod_1.z.literal('OUTBOUND_CHECK_INACTIVE'),
            zod_1.z.literal('REJECTED_BY_ACK'),
            zod_1.z.literal('UNKNOWN'),
        ]),
    }),
});
exports.cmcGenesysGoodParcelProducedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_GOOD_PARCEL_PRODUCED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysBadParcelProducedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_BAD_PARCEL_PRODUCED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        reasonCode: zod_1.z.string(),
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysParcelRejectedAtLabellerSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_REJECTED_AT_LABELLER'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
        // TODO(WMS-735): address orphaned todo
        // eslint-disable-next-line todo-plz/ticket-ref
        reason: zod_1.z.string(), // TODO: Update to actual reasons when CMC provides them after commissioning
    }),
});
exports.cmcGenesysParcelLabelNotFound = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_LABEL_NOT_FOUND'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysParcelLabelledSuccessfullySchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_LABELLED_SUCCESSFULLY'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
    }),
});
exports.cmcGenesysTroubleshootLabelPrintingSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TROUBLESHOOT_LABEL_PRINTING'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
    }),
});
exports.cmcGenesysTroubleshootLabelPrintingFailedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TROUBLESHOOT_LABEL_PRINTING_FAILED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
        reason: zod_1.z.string().optional(),
    }),
});
exports.cmcGenesysTroubleshootLabelPrintedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_TROUBLESHOOT_LABEL_PRINTED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
    }),
});
exports.FulfilmentOrdersSelectedForAutostoreHandlPicking = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDERS_SELECTED_FOR_AUTOSTORE_HAND_PICKING'),
    payload: zod_1.z.object({
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.HandPickingFulfilmentOrdersReverted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('HAND_PICKING_FULFILMENT_ORDERS_REVERTED'),
    payload: zod_1.z.object({
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.FulfilmentOrdersAutostoreHandPickingStarted = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('FULFILMENT_ORDERS_AUTOSTORE_HAND_PICKING_STARTED'),
    payload: zod_1.z.object({
        fulfilmentOrderIds: zod_1.z.array(base_1.StringId),
    }),
});
exports.orderItemsUnallocatedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ORDER_ITEMS_UNALLOCATED'),
    payload: zod_1.z.object({
        itemsToUnallocate: zod_1.z.record(zod_1.z.object({
            quantity: zod_1.z.number(),
        })),
    }),
});
exports.orderManuallyMarkedAsPackedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ORDER_MANUALLY_MARKED_AS_PACKED'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        toteId: base_1.StringId.optional(),
        packingMethod: zod_1.z.literal('CMC_GENESYS'), // change this to union in the future
    }),
});
exports.orderForcedToSendToCmcGenesysSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ORDER_FORCED_TO_SEND_TO_CMC_GENESYS'),
    payload: zod_1.z.object({
        toteId: base_1.StringId,
        fulfilmentOrderId: base_1.StringId,
        picklistId: base_1.StringId,
    }),
});
exports.staleOrdersIdentifiedSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('STALE_ORDERS_IDENTIFIED'),
    payload: zod_1.z.object({
        fulfilmentOrderIds: base_1.StringId.array(),
    }),
});
exports.orderReturnedToPackingQueueSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('ORDER_RETURNED_TO_PACKING_QUEUE'),
    payload: zod_1.z.object({
        fulfilmentOrderId: zod_1.z.string().min(1),
        picklistId: zod_1.z.string().min(1),
        toteId: zod_1.z.string().min(1),
    }),
});
exports.toteSentToTroubleshootingFromCmcSchema = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('TOTE_SENT_TO_TROUBLESHOOTING_FROM_CMC'),
    payload: zod_1.z.object({
        toteId: zod_1.z.string().min(1),
    }),
});
const cmcGenesysTroubleshootReasonSchema = zod_1.z.union([
    zod_1.z.literal('CMC_GENESYS_FAILED_TO_CREATE_BOX'),
    zod_1.z.literal('CMC_GENESYS_FAILED_TO_PRINT_LABEL'),
    zod_1.z.literal('CMC_GENESYS_ITEM_NOT_SUITABLE'),
    zod_1.z.literal('CMC_GENESYS_WRONG_BARCODE'),
    zod_1.z.literal('CMC_GENESYS_OTHER'),
]);
exports.cmcGenesysParcelTransferredToTroubleshoot = base_1.eventBaseSchema.extend({
    type: zod_1.z.literal('CMC_GENESYS_PARCEL_TRANSFERRED_TO_TROUBLESHOOT'),
    payload: zod_1.z.object({
        fulfilmentOrderId: base_1.StringId,
        referenceId: zod_1.z.string(),
        troubleshootReason: cmcGenesysTroubleshootReasonSchema,
    }),
});
exports.FulfilmentOrderEvent = zod_1.z.union([
    exports.fulfilmentOrderAcceptedSchema,
    exports.fulfilmentOrderRejectedSchema,
    exports.fulfilmentOrderRatesRetrievedSchema,
    exports.fulfilmentOrderRateRequestFailedSchema,
    exports.fulfilmentOrderDispatchedSchema,
    exports.fulfilmentOrderManuallyDispatchedSchema,
    exports.fulfilmentOrderCancelledSchema,
    exports.FulfilmentOrderCancelRejected,
    exports.cmcGenesysToteValidationFailedSchema,
    exports.cmcGenesysParcelValidationFailedSchema,
    exports.cmcGenesysParcelMeasuredSchema,
    exports.cmcGenesysParcelMeasuredFailedSchema,
    exports.cmcGenesysToteScannedSchema,
    exports.cmcGenesysToteExitedInFailureSchema,
    exports.cmcGenesysGoodParcelProducedSchema,
    exports.cmcGenesysBadParcelProducedSchema,
    exports.cmcGenesysParcelLabelledSuccessfullySchema,
    exports.cmcGenesysParcelRejectedAtLabellerSchema,
    exports.cmcGenesysParcelLabelNotFound,
    exports.cmcGenesysParcelRemovedBeforeExitSchema,
    exports.cmcGenesysTroubleshootLabelPrintingSchema,
    exports.cmcGenesysTroubleshootLabelPrintingFailedSchema,
    exports.cmcGenesysTroubleshootLabelPrintedSchema,
    exports.cmcGenesysIneligibleOrderDetectedSchema,
    exports.cmcGenesysOrderAlreadyPackedSchema,
    exports.shippingRateSelectedSchema,
    exports.fulfilmentOrderDeletedSchema,
    exports.FulfilmentOrdersSelectedForAutostoreHandlPicking,
    exports.FulfilmentOrdersAutostoreHandPickingStarted,
    exports.orderItemsUnallocatedSchema,
    exports.HandPickingFulfilmentOrdersReverted,
    exports.fulfilmentOrderTagsChangedSchema,
    exports.fulfilmentOrderLineItemsChangeFailedSchema,
    exports.fulfilmentOrderLineItemsChangedSchema,
    exports.orderManuallyMarkedAsPackedSchema,
    exports.orderForcedToSendToCmcGenesysSchema,
    exports.staleOrdersIdentifiedSchema,
    exports.orderReturnedToPackingQueueSchema,
    exports.toteSentToTroubleshootingFromCmcSchema,
    exports.cmcGenesysParcelTransferredToTroubleshoot,
]);
