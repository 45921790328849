"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPickPortPickingModesOutcomesSchema = exports.RaiseNoPaperProblemOutcome = exports.RaisePickProblemOutcome = exports.PickCommandsOutcome = exports.validatePickItemBarcodeOutcomeSchema = exports.noCurrentPickAssignedToPortSchema = exports.invalidPickItemBarcodeSchema = exports.pushCompletedTotesOutcomeSchema = exports.determineZeroCountCrossCheckRequiredOutcomeSchema = exports.checkBinHasArrivedOutcomeSchema = exports.retryCloseBinOutcomeSchema = exports.PlacePaperInTotesOutcome = exports.UpdateAvailablePickQuantityOutcome = exports.UndoPushPickTotesOutcome = exports.ReplacePickTotesOutcome = exports.RetryPushPickTotesToConveyorOutcome = exports.PushPickTotesOutcome = exports.UndoPreviousPickOutcome = exports.GetNextItemOutcome = exports.cancelMissingItemResolutionOutcomeSchema = exports.detectMissingItemOutcomeSchema = exports.confirmMissingItemDuringPickOutcomeSchema = exports.CompletePickOutcome = exports.UnlinkAllTotesOutcome = exports.StartPickingOutcome = exports.CancelToteLinkOutcome = exports.ConfirmToteLinkOutcome = exports.ScanToteOutcome = exports.ScanToteLocationOutcome = exports.NoCurrentTransfer = exports.LocationNotFound = exports.InvalidLocationIdFormat = exports.MissingCurrentAutostoreTaskId = exports.InvalidQuantity = exports.InvalidTote = exports.InvalidLocation = exports.PlaceTotesOutcome = exports.SelectPortCategoriesOutcome = exports.ClosePickPortOutcome = exports.OpenPickPortOutcome = void 0;
const z = __importStar(require("zod"));
const model_1 = require("../../model");
const generic_1 = require("./generic");
const port_1 = require("./port");
const warehouse_1 = require("./warehouse");
exports.OpenPickPortOutcome = z.union([
    port_1.OpenPickPortSuccess,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoMatchingBins,
    port_1.NoReadyBins,
    port_1.InvalidPortType,
    port_1.InvalidPortStatus,
    port_1.PortNotFound,
    port_1.unableToGetAutostorePortStatusSchema,
    port_1.portOpenedWithTaskAssigned,
]);
const closePickPortJobEnqueueFailedSchema = z.object({
    outcome: z.literal('CLOSE_PICK_PORT_JOB_ENQUEUE_FAILED'),
});
exports.ClosePickPortOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoCurrentBin,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    closePickPortJobEnqueueFailedSchema,
]);
exports.SelectPortCategoriesOutcome = z.union([generic_1.Success, generic_1.GenericError]);
exports.PlaceTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.InvalidLocation = z.object({
    outcome: z.literal('INVALID_LOCATION_ID'),
});
exports.InvalidTote = z.object({
    outcome: z.literal('INVALID_TOTE'),
    error: z.optional(z.string()),
});
exports.InvalidQuantity = z.object({
    outcome: z.literal('INVALID_QUANTITY'),
});
exports.MissingCurrentAutostoreTaskId = z.object({
    outcome: z.literal('MISSING_CURRENT_AUTOSTORE_TASK_ID'),
});
exports.InvalidLocationIdFormat = z.object({
    outcome: z.literal('INVALID_LOCATION_ID_FORMAT'),
    locationId: z.string(),
});
exports.LocationNotFound = z.object({
    outcome: z.literal('LOCATION_NOT_FOUND'),
    locationId: z.string(),
});
exports.NoCurrentTransfer = z.object({
    outcome: z.literal('NO_CURRENT_TRANSFER'),
});
const NoAutostorePortStatus = z.object({
    outcome: z.literal('COULD_NOT_GET_AUTOSTORE_PORT_STATUS'),
    portId: z.number(),
});
const BinMismatchAtPort = z.object({
    outcome: z.literal('AUTOSTORE_BIN_AT_PORT_MISMATCH'),
    autostorePortBinId: z.number().optional(),
    wmsPortBinId: z.number(),
});
const TaskMismatchAtPort = z.object({
    outcome: z.literal('AUTOSTORE_PORT_TASK_ID_MISMATCH'),
    autostorePortTaskId: z.number().optional(),
    wmsNextPortTaskId: z.number().optional(),
});
const BinNotReady = z.object({
    outcome: z.literal('AUTOSTORE_BIN_AT_PORT_NOT_READY'),
    autostorePortBinId: z.number().optional(),
});
const TaskGroupMissingBins = z.object({
    outcome: z.literal('TASK_GROUP_MISSING_BINS'),
    taskGroupId: z.string(),
    taskIdsMissingBin: z.array(z.string()),
});
const pushCompletedTotesJobEnqueueFailedSchema = z.object({
    outcome: z.literal('PUSH_COMPLETED_TOTES_JOB_ENQUEUE_FAILED'),
});
const pickingModesUnchangedSchema = z.object({
    outcome: z.literal('PICKING_MODES_UNCHANGED'),
    pickingModeNames: model_1.pickingModeNameStateSchema.array(),
});
const getNextItemJobEnqueueFailedSchema = z.object({
    outcome: z.literal('GET_NEXT_ITEM_JOB_ENQUEUE_FAILED'),
});
exports.ScanToteLocationOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    exports.InvalidLocation,
    exports.InvalidLocationIdFormat,
    port_1.PortNotFound,
]);
exports.ScanToteOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    exports.InvalidTote,
    exports.InvalidLocationIdFormat,
    exports.LocationNotFound,
    port_1.PortNotFound,
]);
exports.ConfirmToteLinkOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    exports.InvalidLocation,
    exports.InvalidTote,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.NoTaskGroupAvailable,
    port_1.NoReadyBins,
    port_1.MissingTaskId,
    port_1.NoCurrentBin,
    exports.InvalidLocationIdFormat,
    port_1.NoTaskAssigned,
    getNextItemJobEnqueueFailedSchema,
]);
exports.CancelToteLinkOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    exports.InvalidLocation,
    exports.InvalidTote,
    generic_1.GenericError,
]);
exports.StartPickingOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.NoTaskGroupAvailable,
    port_1.NoReadyBins,
    port_1.MissingTaskId,
    port_1.PortNotFound,
    port_1.NoCurrentBin,
]);
exports.UnlinkAllTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.PortNotFound,
]);
exports.CompletePickOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.NoPickAssigned,
    port_1.NoCurrentBin,
    port_1.NoTaskAssigned,
    port_1.NoTaskGroupAssigned,
    port_1.PortNotFound,
    port_1.OverPickOccurred,
]);
exports.confirmMissingItemDuringPickOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.NoPickAssigned,
    port_1.NoCurrentBin,
    port_1.NoTaskAssigned,
    port_1.NoTaskGroupAssigned,
    port_1.noPicklistAssignedSchema,
    port_1.noToteAssignedToToteLocationSchema,
    port_1.PortNotFound,
    port_1.OverPickOccurred,
]);
exports.detectMissingItemOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.cancelMissingItemResolutionOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.GetNextItemOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.NoTaskAssigned,
]);
exports.UndoPreviousPickOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.GenericError,
    generic_1.InvalidState,
    port_1.NoPreviousPick,
    port_1.NoCurrentBin,
    port_1.PortNotFound,
]);
exports.PushPickTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.GenericError,
    generic_1.InvalidState,
]);
exports.RetryPushPickTotesToConveyorOutcome = z.union([
    generic_1.Success,
    port_1.RetryPushToteToConveyorFailed,
]);
exports.ReplacePickTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    generic_1.InvalidState,
    port_1.PortNotFound,
]);
exports.UndoPushPickTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.NoPickTotesPushed,
    port_1.PortNotFound,
]);
exports.UpdateAvailablePickQuantityOutcome = z.union([
    generic_1.Success,
    exports.InvalidQuantity,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    port_1.InvalidPortStatus,
    port_1.NoPickAssigned,
    port_1.NoCurrentBin,
    port_1.NoTaskAssigned,
    generic_1.GenericError,
    generic_1.InvalidState,
    TaskGroupMissingBins,
]);
exports.PlacePaperInTotesOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortType,
    port_1.InvalidPortStatus,
    port_1.PortNotFound,
]);
exports.retryCloseBinOutcomeSchema = z.union([
    generic_1.Success,
    generic_1.GenericError,
    port_1.InvalidPortType,
    port_1.InvalidPortStatus,
    exports.MissingCurrentAutostoreTaskId,
    port_1.NoCurrentBin,
    port_1.PortNotFound,
    port_1.NoTaskGroupAssigned,
    port_1.NoPickingModesFound,
    port_1.InvalidPortType,
    warehouse_1.warehouseNotFoundOutcomeSchema,
    warehouse_1.warehouseWithAutostoreNotFoundSchema,
]);
exports.checkBinHasArrivedOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    generic_1.GenericError,
    generic_1.NoAutostore,
    port_1.NoMatchingBins,
    port_1.NoReadyBins,
    port_1.PortNotFound,
    port_1.NoCurrentBin,
    exports.NoCurrentTransfer,
    port_1.InvalidPortType,
    NoAutostorePortStatus,
    BinMismatchAtPort,
    TaskMismatchAtPort,
    BinNotReady,
]);
exports.determineZeroCountCrossCheckRequiredOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.NoCurrentBin,
    port_1.PortNotFound,
    port_1.NoPickAssigned,
    port_1.partitionNotFoundOutcomeSchema,
]);
exports.pushCompletedTotesOutcomeSchema = z.union([
    generic_1.Success,
    port_1.PortNotFound,
    port_1.InvalidPortStatus,
    port_1.NoPickAssigned,
    pushCompletedTotesJobEnqueueFailedSchema,
]);
exports.invalidPickItemBarcodeSchema = z.object({
    outcome: z.literal('INVALID_PICK_ITEM_BARCODE'),
    expectedBarcode: z.string(),
    scannedBarcode: z.string(),
});
exports.noCurrentPickAssignedToPortSchema = z.object({
    outcome: z.literal('NO_CURRENT_PICK_ASSIGNED_TO_PORT'),
});
exports.validatePickItemBarcodeOutcomeSchema = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
    exports.invalidPickItemBarcodeSchema,
    exports.noCurrentPickAssignedToPortSchema,
]);
exports.PickCommandsOutcome = z.union([
    exports.OpenPickPortOutcome,
    exports.ClosePickPortOutcome,
    exports.PlaceTotesOutcome,
    exports.ScanToteLocationOutcome,
    exports.ScanToteOutcome,
    exports.ConfirmToteLinkOutcome,
    exports.CancelToteLinkOutcome,
    exports.StartPickingOutcome,
    exports.UnlinkAllTotesOutcome,
    exports.CompletePickOutcome,
    exports.confirmMissingItemDuringPickOutcomeSchema,
    exports.detectMissingItemOutcomeSchema,
    exports.cancelMissingItemResolutionOutcomeSchema,
    exports.GetNextItemOutcome,
    exports.UndoPreviousPickOutcome,
    exports.PushPickTotesOutcome,
    exports.RetryPushPickTotesToConveyorOutcome,
    exports.ReplacePickTotesOutcome,
    exports.UndoPushPickTotesOutcome,
    exports.UpdateAvailablePickQuantityOutcome,
    exports.PlacePaperInTotesOutcome,
    exports.retryCloseBinOutcomeSchema,
    exports.checkBinHasArrivedOutcomeSchema,
    exports.determineZeroCountCrossCheckRequiredOutcomeSchema,
    exports.pushCompletedTotesOutcomeSchema,
    closePickPortJobEnqueueFailedSchema,
    exports.validatePickItemBarcodeOutcomeSchema,
]);
exports.RaisePickProblemOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.RaiseNoPaperProblemOutcome = z.union([
    generic_1.Success,
    port_1.InvalidPortStatus,
    port_1.InvalidPortType,
    port_1.PortNotFound,
]);
exports.setPickPortPickingModesOutcomesSchema = z.discriminatedUnion('outcome', [
    generic_1.Success.extend({ pickingModeNames: model_1.pickingModeNameStateSchema.array() }),
    port_1.PortNotFound,
    port_1.InvalidPortType,
    pickingModesUnchangedSchema,
]);
