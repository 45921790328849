"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.merchantApiSchema = void 0;
const zod_1 = require("zod");
const zod_http_schemas_1 = require("zod-http-schemas");
const merchant_1 = require("../commands/outcomes/merchant");
const model_1 = require("../model");
exports.merchantApiSchema = (0, zod_http_schemas_1.createHttpSchema)({
    'GET /merchants': {
        responseBody: zod_1.z.object({
            outcome: zod_1.z.literal('SUCCESS'),
            merchants: zod_1.z.array(model_1.merchantSchema),
        }),
    },
    'GET /merchants/:merchantId': {
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
                merchant: model_1.merchantSchema,
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
        ]),
    },
    'POST /merchants': {
        requestBody: zod_1.z.object({
            merchant: zod_1.z.object({
                id: zod_1.z.string(),
                name: zod_1.z.string(),
            }),
            warehouseId: zod_1.z.string(),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_ALREADY_EXISTS'),
                existingMerchant: zod_1.z.object({
                    id: zod_1.z.string(),
                    name: zod_1.z.string(),
                }),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
                newMerchant: zod_1.z.object({
                    id: zod_1.z.string(),
                    name: zod_1.z.string(),
                }),
            }),
        ]),
    },
    'POST /merchants/:merchantId/assign-virtual-bin': {
        requestBody: zod_1.z.object({
            warehouseId: model_1.warehouseSchema.shape.id,
        }),
        responseBody: merchant_1.assignVirtualBinToMerchantOutcomeSchema,
    },
    'POST /merchants/:merchantId/putaway-mode': {
        requestBody: zod_1.z.object({
            warehouseId: model_1.warehouseSchema.shape.id,
            putawayMode: model_1.putawayModeSchema,
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_CHANGE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'POST /merchants/:merchantId/config': {
        requestBody: zod_1.z.object({
            warehouseId: model_1.warehouseSchema.shape.id,
            config: zod_1.z.object({
                putawayMode: model_1.putawayModeSchema.optional(),
                pickingPriority: model_1.pickingPrioritySchema.optional(),
                packingNote: zod_1.z.string().optional(),
                insertRequired: zod_1.z.boolean().optional(),
                cmcGenesysEligible: zod_1.z.boolean().optional(),
            }),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('INVALID_MERCHANT_WAREHOUSE_CONFIG_SCHEMA'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'POST /merchants/:merchantId/picking-priority': {
        requestBody: zod_1.z.object({
            warehouseId: model_1.warehouseSchema.shape.id,
            pickingPriority: model_1.pickingPrioritySchema,
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('NO_CHANGE'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'POST /merchants/:merchantId/returns-restocking': {
        requestBody: zod_1.z.object({
            warehouseId: model_1.warehouseSchema.shape.id,
            enabled: zod_1.z.boolean(),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
        ]),
    },
    'POST /merchants/:merchantId/stores/:storeId/configure': {
        requestBody: zod_1.z.object({
            config: model_1.merchantStoreConfigSchema,
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('STORE_NOT_FOUND'),
            }),
        ]),
    },
    'POST /merchants/:merchantId/stores/:storeId/configureWarehouse': {
        requestBody: zod_1.z.object({
            warehouseId: zod_1.z.string(),
            config: zod_1.z.object({
                isManualPack: zod_1.z.boolean(),
            }),
        }),
        responseBody: zod_1.z.union([
            zod_1.z.object({
                outcome: zod_1.z.literal('SUCCESS'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('MERCHANT_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('STORE_NOT_FOUND'),
            }),
            zod_1.z.object({
                outcome: zod_1.z.literal('WAREHOUSE_NOT_FOUND'),
            }),
        ]),
    },
});
